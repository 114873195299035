
//window.messagesWallRef = firebase.database().ref('messages/tours/' + tour_id + '/wall');
//window.messagesCardRef = firebase.database().ref('messages/tours/' + tour_id + '/' + photo_id) ;
//
//
//messagesWallRef.on('child_added', function (data) {
//  muser_id = data.val().user_id
//  mcontent = data.val().content
//  mtime = moment(data.val().created).fromNow();    
//  get_user = firebase.database().ref("reports/tour/" + tour_id + "/users/" + muser_id).once('value').then(function (user) {
//    //console.log(user.val(), 'teste')
//    var muser_checkin = user.val().checkin;
//    var muser_image = user.val().image;
//    var muser_name = user.val().name;
//    var muser_url = user.val().providerUrl;
//    if (muser_checkin == true){
//      var muser_checkin_id = user.val().checkin_photo;
//      $('.wall-message').prepend('<li class="animated fadeIn"><img class="avatar img-responsive" src="' + muser_image + '"><a href="javascript:gotohotspot(\'' + muser_id + '\',\'' + muser_checkin_id + '\')">' + muser_name + '</a><p>' + mcontent + '</p><p class="pull-right">' + mtime+ '</p></li>');
//    }
//    else {
//      $('.wall-message').prepend('<li class="animated fadeIn"><img class="avatar img-responsive" src="' + muser_image + '"><a href="' + muser_url + '">' + muser_name + '</a><p>' + mcontent + '</p><p class="pull-right">' + mtime + '</p></li>');
//    }
//  });
//  
//});
//
//messagesWallRef.on('child_changed', function (data) {
//  console.log('muda', data );
//    
//        //  App.watcher.wall(data)
//});
//
//messagesWallRef.on('child_removed', function (data) {
//  // console.log('remove', data);
//  // App.watcher.wall(data)cont
//});
//
//
//
//messagesCardRef.on('child_added', function (data) {
//  get_card = $("#" + data.key)
//  get_card.find('.card-message li').remove()
//  // console.log('muda', data.val() );
//        //  App.watcher.wall(data)
//});
//
//messagesCardRef.on('child_changed', function (data) {
//  // console.log('remove', data);
//  // App.watcher.wall(data)cont
//});
//

// messagesHotRef.on('child_changed', function(data) {
//   // addCommentElement(postElement, data.key, data.val().text, data.val().author);
//   // console.log(data.key)
//   // console.log(data.val())
//   id = data.key
//   get_messages =  {"user_id":  currentUser.id, "tour_id": tour_id, "photo_id": photo_id, "card_id": data.key, "type": "card"}
//   $.ajax({
//       url: '/api/v1/message.json',
//       type: 'GET',
//       data: get_messages,
//       success: function(data) {
//         updateCard(data, id);      
//          console.log('teste');     
//       },
//       // beforeSend: function() { $('.overlay').show(); $('.loading').addClass('loading-animate'); },
//       // complete: function() { $('.overlay').hide(); $('.loading').removeClass('loading-animate'); }
//     });
// });



