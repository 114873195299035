// Send Message card
  window.sendMessage = function(user, id, content) {
          
      if(content != '') {
          $.ajax({
              
              url: '/api/v1/message.json?tour_id='+tour_id+'&photo_id='+currentUser.photo_id+'&user_id='+user+'&type=card&card_id='+id+'&content='+content+'&is_group='+is_group + '&card_url=' + window.origin ,
              cache: false,
              type: 'PUT',
              success: function(data) {
                // console.log(data);
                // console.log(this);  
                if (data.user.checking) {
                  $('.card-message').prepend('<li class="animated fadeIn"><img class="avatar img-responsive" src="' + data.user.image + '"><a href="javascript:gotohotspot(\'' + data.user.user_id + '\',\'' + data.user.checking + '\')">' + data.user.name + '</a><p>' + data.content + '</p><p class="pull-right">' + data.created + '</p></li>');
                } else {
                  $('.card-message').prepend('<li class="animated fadeIn"><img class="avatar img-responsive" src="' + data.user.image + '"><a href="' + data.user.providerUrl + '">' + data.user.name + '</a><p>' + data.content + '</p><p class="pull-right">' + data.created + '</p></li>');
                }
                      
              },
              beforeSend: function() { $('.overlay').show(); $('.loading').addClass('loading-animate'); },
              complete: function() { 
                  $('.overlay').hide(); 
                  $('.loading').removeClass('loading-animate'); 
                  $('input.message').val('');
                  $(id + ' i.fa-paper-plane').removeClass('animated wobble'); 
                  $(id + ' .message').attr('placeholder', 'Escreva sua mensagem...');
                  
              }
        });
      }
      else {
          $(id + ' .message').attr('placeholder', 'Digite alguma mensagem!');
          $(id + ' i.fa-paper-plane').addClass('animated wobble');
      }
  
  return false;
  } 