

const firebase = require('firebase');


// init firebase
// var config = firebase.initializeApp({
//     apiKey: "AIzaSyAubv69Kl-qbVmL4Z1ACfvhtpHLW3Rq_4M",
//     authDomain: "gigafans-8cd43.firebaseapp.com",
//     databaseURL: "https://gigafans-8cd43.firebaseio.com",
//     projectId: "gigafans-8cd43",
//     storageBucket: "images.gigafans.com",
//     messagingSenderId: "950279574805"
//   });


var config = firebase.initializeApp({
    apiKey: "AIzaSyAubv69Kl-qbVmL4Z1ACfvhtpHLW3Rq_4M",
    authDomain: "gigafans.firebaseapp.com",
    databaseURL: "https://gigafans.firebaseio.com",
    projectId: "gigafans-8cd43",
    storageBucket: "images.gigafans.com",
    messagingSenderId: "950279574805"
});




firebase.firestore().settings({
    // Enable offline support
    // persistence: true
});

var db = firebase.firestore();
var currentUser = {}



// firebase.database().ref('quiz/'+ tour_id +'/' + user.uid);

// console.log('oi', oi);