
require("cj/hellojs")
var krpano = document.getElementById("tourId");
var trackMouse
// need setupjs



 if (tour_id == '107') {
    $('#login').remove();
    $('.overlay').remove();
 }

// gigacard
 if (window.gigacard) {
     krpano.set('action[intro].content', 3);
    setTimeout(function(){ 
 
      if (window.photo_id != window.currentUser.checkin_photo) {
           krpano.call("loadscene(gigaphoto_" + window.currentUser.checkin_photo + ",null,MERGE,ZOOMBLEND(1,2,'easeInOutSin'));");
           window.currentUser.photo_id = window.currentUser.checkin_photo;
           krpano.call("looktohotspot(brut" + window.gigacard+ ", 0, smooth(100,50,20)");
       }
       else {
           krpano.call("looktohotspot(brut" + window.gigacard + ", 0, smooth(100,50,20)");
       }
    }, 3000);

}

// Change Scene

window.changeScene = function(spot_id) {
    
    console.log(spot_id);
    window.currentUser.photo_id = spot_id
    xml = "/api/v1/tour.xml?tour_id=" + tour_id 
    
    // App.tour.track_photo(currentUser)
    
    // krpano.call("looktohotspot(" + spot_id + ", 0, smooth(100,50,20)");

    krpano.call("loadpanoscene("+ xml+",gigaphoto_"+ spot_id +",null,MERGE,ZOOMBLEND(1,2,'easeInOutSine'));");
   
}



// Snapshot


function getscreenshot(img) {
        snap_data = { "tour_id": tour_id, "photo_id": currentUser.photo_id, "user_id": currentUser.user_id, "snap": img}

        $.ajax({
            url: '/api/v1/tour/snapshot/take.json',
            type: 'POST',
            cache: false,
            data: snap_data,
            beforeSend: function() {
                $('.confirm-check').addClass('loading-animate');
                $('img.aim').addClass('animated bounceOut');
                $('img.aim').hide();
                $('a.close-checkin').hide();
                console.log('carregando snapshot');
            },
            success: function(data) {
                console.log('carregou snapshot');
                $('.confirm-check').addClass('animated flipOutX');
                $('.confirm-check').hide();
                $('.mycheckin').show();
                $('#button-circle').css('background-color', '#fff');
                if (tour_id == '77') {
                    $('#getSnap').attr('src', data);
                    $("#sharemoment").show();
                }
              
                
            }
        });

        
        console.log('haha')
   
}

$('.send-share2').click(function(){
    var snapIMG = $('#getSnap').attr('src');
    // $('head').append("<meta property=\"og:image\" content="+snapIMG+" />");
    $('#faceog_url').attr('content', 'https://dev.gigafans.com/?gigacard=7Dfyb28htET4pvVxMKPQYeWsfq02');
    $('#faceog_image').attr('content', snapIMG);

    FB.ui({
        method: 'share_open_graph',
        action_type: 'og.shares',
        href: 'https://dev.gigafans.com/?gigacard=7Dfyb28htET4pvVxMKPQYeWsfq02',
        action_properties: JSON.stringify({
            object : {
               'og:url': 'https://dev.gigafans.com/?gigacard=7Dfyb28htET4pvVxMKPQYeWsfq02',
               'og:title': 'teste do papi',
               'og:description': 'teo',
               'og:image:width': '1380',
               'og:image:height': '720',
               'og:image': 'https://images.gigafans.com/snapshot/77/7Dfyb28htET4pvVxMKPQYeWsfq02/77_1110_7Dfyb28htET4pvVxMKPQYeWsfq02.png',
               'og:image:url': 'https://images.gigafans.com/snapshot/77/7Dfyb28htET4pvVxMKPQYeWsfq02/77_1110_7Dfyb28htET4pvVxMKPQYeWsfq02.png',
               'og:image:secure_url': 'https://images.gigafans.com/snapshot/77/7Dfyb28htET4pvVxMKPQYeWsfq02/77_1110_7Dfyb28htET4pvVxMKPQYeWsfq02.png'
            }
        })
    });
    console.log()
});

// Track Mouse


	function track_mouse_interval_callback()
	{
		var mx = krpano.get("mouse.x");
		var my = krpano.get("mouse.y");
		var pnt = krpano.screentosphere(mx,my);
		var h = pnt.x;
        var v = pnt.y;

        var hlookat = krpano.get("view.hlookat");
        var vlookat = krpano.get("view.vlookat");
        var fov = krpano.get("view.fov") ;


        console.log('ath="' + h.toFixed(2) + '" atv="' + v.toFixed(2) + '"')

        console.log(hlookat.toFixed(2), vlookat.toFixed(2), fov.toFixed(2))

        // currentUser.track = {ath: h.toFixed(2), atv: v.toFixed(2) }
        // App.tour.track_mouse(currentUser);      
        
        
        $('.aim-info').html('ath="' + hlookat.toFixed(2) + '" atv="' + vlookat.toFixed(2) + '"' + '" fov="' + fov.toFixed(2) + '"');
        // console.log(gon.tagzoneV.includes(2.21))
        if (vlookat >= 0 ) {
            $('.aim-button').html('Não Pode');
            $('img.aim').css({
                'background': '#d43737'
            })
            $('span.confirm-check').addClass('youcantsithere')
            $('.confirm-check > i').removeClass('fa fa-check')
            $('.confirm-check > i').addClass('fa fa-ban').css({
                'padding-left': '25px'
            })

        }else{
            $('.aim-button').html('Pode');
            $('img.aim').css({
                'background': 'transparent'
            })
            $('span.confirm-check').removeClass('youcantsithere')
            $('.confirm-check > i').removeClass('fa fa-ban')
            $('.confirm-check > i').addClass('fa fa-check').css({
                'padding-left': '20px'
            })
        }


        
	
    }
    // track_mouse_interval_id = setInterval(track_mouse_interval_callback, 1000.0 / 60.0);


// Mira Screen


  // console.log(currentUser.isAnonymous)
  // Call Login Screen
  $('#button-circle a.makecheckin').click(function (event) {
      // Anonymous Login
      if (currentUser.isAnonymous) {

          overlay.show();
          login.show();
          trigger.removeClass('is-closed');
          isClosed = false;
          return false;
      } else {

          $('#button-circle > a').hide();
          $('#button-circle').css('background-color', 'transparent');
          $('#button-circle > span').show();
          $('img.aim').show();
          $('a.close-checkin').show();
          $('.fa-check').show();

        // hotstop Zone    
          $('.tempAim').show();

          if (tour_id == '208') {
            trackMouse = setInterval(track_mouse_interval_callback, 1000)    
          }
          

          return false;
      }

  });


// Cancel Check-in

$('a.close-checkin').click(function(event) {
    $(this).hide();
    $('img.aim').addClass('animated bounceOut');

    // temp 
    $('.tempAim').hide();
    clearInterval(trackMouse)

    setTimeout(function(){
        $('img.aim').hide();
        $('.confirm-check').addClass('animated flipOutX');
    },1000);
    setTimeout(function(){
        $('.confirm-check').removeClass('flipOutX');
        $('.confirm-check').hide();
        $('.makecheckin').show();
        $('img.aim').removeClass('animated bounceOut');
        $('#button-circle').css('background-color', '#005892');
    },1000);

    return false;
});
    
// Make Chekin

$( ".confirm-check" ).click(function() {

    if($(this).hasClass('youcantsithere')) {
        return false;
    }

    if(tour_id == '77'){
        krpano.call("makescreenshot");
    }


    clearInterval(trackMouse)
    
    var camera = new Audio('http:///images.gigafans.com/assets/player/sounds/camera.wav');

    $('.confirm-check').addClass('loading-animate');
    $('.confirm-check > i').hide();
    
    // Play camera sound
    camera.play();

        if(tour_id != '77'){
            $('img.aim').addClass('animated bounceOut');
            setTimeout(function(){
                $('img.aim').hide();
                $('a.close-checkin').hide();
                $('.confirm-check').addClass('animated flipOutX');
                setTimeout(function(){
                    $('.confirm-check').hide();
                    $('.makecheckin').hide();
                },2000);             
                $('.mycheckin').show();
                $('#button-circle').css('background-color', '#fff');
                $("#sharemoment").show();
            },1000); 
        }


        if(currentUser.provider == 'facebook.com') {
            $('#sharemoment .btn-facebook').show();
            $('#sharemoment .btn-twitter').hide();
            $('#sharemoment .btn-google').hide();
            $('#sharemoment .btn-instagram').hide();
        } else if(currentUser.provider == 'twitter.com') {
            $('#sharemoment .btn-twitter').show();
            $('#sharemoment .btn-facebook').hide();
            $('#sharemoment .btn-google').hide();
            $('#sharemoment .btn-instagram').hide();
        } else if(currentUser.provider == 'google.com'){
            $('#sharemoment .btn-google').show();
            $('#sharemoment .btn-facebook').hide();
            $('#sharemoment .btn-twitter').hide();
            $('#sharemoment .btn-instagram').hide();
        } else if(currentUser.provider == 'instagram.com'){
            $('#sharemoment .btn-instagram').show();
            $('#sharemoment .btn-facebook').hide();
            $('#sharemoment .btn-twitter').hide();
            $('#sharemoment .btn-google').hide();
        }


        // Add Card
            // hotsppot = card

            var ath = krpano.get("view.hlookat");
            var atv = krpano.get("view.vlookat");
            var hotspotName = "brut" + currentUser.user_id
            krpano.call("addhotspot(" + hotspotName + ")");
            krpano.set("hotspot[" + hotspotName + "].id", currentUser.user_id);
            krpano.set("hotspot["+hotspotName+"].onclick", "hotspotClick("+ hotspotName+")"); 
            krpano.set("hotspot["+hotspotName+"].ath", ath);
            krpano.set("hotspot["+hotspotName+"].atv", atv);
            krpano.set("hotspot["+hotspotName+"].width", "30");
            krpano.set("hotspot["+hotspotName+"].height", "30");
            krpano.set("hotspot["+hotspotName+"].distorted", false);

            // New Function
    


    card_data = { "tour_id": tour_id, "photo_id": currentUser.photo_id, "user_id": currentUser.user_id, "ath": ath, "atv": atv, "distorted": "false", "style": pin_style}
    // added image

            card_data.image = $('.avatar').attr('src')

                $.ajax({
                    url: '/api/v1/tour/card/add.json',
                    type: 'POST',
                    data: card_data,
                    cache: false,
                    success: function(data) { 


                        $('.card').attr('id', currentUser.user_id);
                        currentUser.checkin = true;
                        currentUser.checkin_photo = currentUser.photo_id;
                        krpano.set("hotspot["+hotspotName+"].url", pin_url);
                        //  snap test put url on share card
                        if(tour_id == '77'){
                            snapUrl = 'https://images.gigafans.com/snapshot/' + tour_id + '/' + currentUser.user_id + '/'+tour_id+'_'+currentUser.photo_id+'_'+currentUser.user_id+'.png';    
                        }

                        if (tour_id == 208) {
                            krpano.set("hotspot[" + hotspotName + "].width", "211");
                            krpano.set("hotspot[" + hotspotName + "].height", "276");
                            krpano.set("hotspot[" + hotspotName + "].url", 'https://images.gigafans.com/snapshot/' + tour_id + '/' + currentUser.user_id + '/' + tour_id + '_' + currentUser.photo_id + '_' + currentUser.user_id + '.png');
                            krpano.set("hotspot[" + hotspotName + "].scale", "0.5");
                        }
                    }
                });
        });

// Make Checkin faceApp

$( ".confirm-checkin-faceApp" ).click(function() {

    if(currentUser.provider == 'facebook.com') {
        $('#sharemoment .btn-facebook').show();
        $('#sharemoment .btn-twitter').hide();
        $('#sharemoment .btn-google').hide();
        $('#sharemoment .btn-instagram').hide();
    } else if(currentUser.provider == 'twitter.com') {
        $('#sharemoment .btn-twitter').show();
        $('#sharemoment .btn-facebook').hide();
        $('#sharemoment .btn-google').hide();
        $('#sharemoment .btn-instagram').hide();
    } else if(currentUser.provider == 'google.com'){
        $('#sharemoment .btn-google').show();
        $('#sharemoment .btn-facebook').hide();
        $('#sharemoment .btn-twitter').hide();
        $('#sharemoment .btn-instagram').hide();
    } else if(currentUser.provider == 'instagram.com'){
        $('#sharemoment .btn-instagram').show();
        $('#sharemoment .btn-google').hide();
        $('#sharemoment .btn-twitter').hide();
        $('#sharemoment .btn-twitter').hide();
    }

    var camera = new Audio('http:///images.gigafans.com/assets/player/sounds/camera.wav');

    $('.confirm-checkin-faceApp').hide();
    
    // Play camera sound
    camera.play();

    $('img.aim').addClass('animated bounceOut');
    setTimeout(function(){
      $('img.aim').hide();
      $('a.close-checkin').hide();
      setTimeout(function(){
  
        $('#nav-menu-faceApp .make-checkin-faceApp').hide();
        $('#nav-menu-faceApp .my-checkin-faceApp').show();
    },2000);
    },1000); 


    // Add Card
    // hotsppot = card

        var ath = krpano.get("view.hlookat");
        var atv = krpano.get("view.vlookat");
        var hotspotName = "brut" + currentUser.user_id
        krpano.call("addhotspot(" + hotspotName + ")");
        krpano.set("hotspot["+hotspotName+"].id", currentUser.user_id);
        krpano.set("hotspot["+hotspotName+"].url", pin_url);
        krpano.set("hotspot["+hotspotName+"].onclick", "hotspotClick("+ hotspotName+")"); 
        krpano.set("hotspot["+hotspotName+"].ath", ath);
        krpano.set("hotspot["+hotspotName+"].atv", atv);
        krpano.set("hotspot["+hotspotName+"].width", "30");
        krpano.set("hotspot["+hotspotName+"].height", "30");
        krpano.set("hotspot["+hotspotName+"].distorted", false);


    card_data = { "tour_id": tour_id, "photo_id": currentUser.photo_id, "user_id": currentUser.user_id, "ath": ath , "atv": atv, "distorted": "false" , "style": pin_style}

        setTimeout(function(){
            $.ajax({
                url: '/api/v1/tour/card/add.json',
                type: 'POST',
                data: card_data,
                success: function(data) {
                    $('.card').attr('id', currentUser.user_id);
                    currentUser.checkin = true;
                    currentUser.checkin_photo = currentUser.photo_id;
                }
            });
        }, 2000);
        

        return false;

});


// Cancel Checkin faceApp

$('a.close-checkin').click(function(event) {
    $(this).hide();
    $('img.aim').addClass('animated bounceOut');
    $('.confirm-checkin-faceApp').hide();
    setTimeout(function(){
        $('img.aim').hide();
    },1000);
    setTimeout(function(){
        $('.confirm-checkin-faceApp').removeClass('flipOutX');
        $('.confirm-checkin-faceApp').hide();
        $('.make-checkin-faceApp').show();
        $('img.aim').removeClass('animated bounceOut');
        $('#button-circle').css('background-color', '#005892');
    },1000);

    return false;
});


// Go to card

$( ".mycheckin, .my-checkin-faceApp" ).click(function() {
    if (currentUser.photo_id != currentUser.checkin_photo) {
        krpano.call("loadscene(gigaphoto_" + currentUser.checkin_photo +",null,MERGE,ZOOMBLEND(1,2,'easeInOutSin'));");     
        currentUser.photo_id = currentUser.checkin_photo;
        krpano.call("looktohotspot(brut" + currentUser.user_id + ", 0, smooth(100,50,20)");
    }
    else{
        krpano.call("looktohotspot(brut" + currentUser.user_id + ", 0, smooth(100,50,20)");
    }
    

    return false;
});




// Card /Hotspot


window.hotspotClicked = function(spot_id) {
     if(tour_id == '77'){
        snapUrl = 'https://images.gigafans.com/snapshot/' + tour_id + '/' + spot_id + '/'+tour_id+'_'+currentUser.photo_id+'_'+currentUser.user_id+'.png'
    }
  
    
    get_card = { "user_id": currentUser.user_id, "tour_id": tour_id, "photo_id": currentUser.photo_id, "card_id": spot_id}
    $.ajax({
        url: '/api/v1/tour/card/detail.json',
        type: 'GET',
        data: get_card,
        cache: false,
        success: function(data) {
             openCard(data);        
        },
        beforeSend: function() { $('.overlay').show(); $('.loading').addClass('loading-animate'); },
        complete: function() { 
            
            $('.overlay').hide(); 
            $('.loading').removeClass('loading-animate'); 
            if (tour_id == '77') {
                $('#getSnap').attr('src', snapUrl)
            }
             
            $('.card').show(); 
            $('.overlay').show(); 
            
        }
      });
    krpano.call("looktohotspot(brut" + spot_id + ", 7, smooth(100,50,20)");

   
}

window.hideHotSpots = function () {
    var hotspot_count = krpano.get('hotspot.count');
    for(i = 0; i < hotspot_count; i++){
      krpano.set('hotspot[' + i + '].visible', false);
    }

}

window.showHotSpots = function () {
    var hotspot_count = krpano.get('hotspot.count');
    for(i = 0; i < hotspot_count; i++){
      krpano.set('hotspot[' + i + '].visible', true);
    }
}


// ZOOM


// $(window).bind('mousewheel', function(e){
//     scroll_value = e.originalEvent.wheelDelta
//     if(scroll_value /7 > 0) {
//             document.getElementById('zoom-slider-vertical').noUiSlider.set(scroll_value);
        
//         console.log(scroll_value);
//     }
//     else{
//             document.getElementById('zoom-slider-vertical').noUiSlider.set(scroll_value);
//         console.log(scroll_value);
//     }
// });


// $(window).on('mousewheel', function(event) {
//     console.log(event.originalEvent.deltaX, event.originalEvent.deltaY, event.originalEvent.deltaFactor);
//       document.getElementById('zoom-slider-vertical').noUiSlider.set(event.originalEvent.deltaY) ;
// });

$('.zoom-in, .menu-gsk-zoomIn').on('mousedown touchstart',function(e){
    timeout = setInterval(function(){
        var hlookat = krpano.get("view.hlookat");
        var vlookat = krpano.get("view.vlookat");
        var fov = krpano.get("view.fov") -7;
        krpano.call("lookto(" + hlookat + ", " + vlookat + ", " + (fov) + ", smooth(100,50,20), false, true, js(Krpano.onmousewheel(get(multireslevel))))");
    }, 500);

    return false;
});

$('.zoom-in, .menu-gsk-zoomIn').on('mouseup touchend', function(e){
    clearInterval(timeout);
});


$('.zoom-in, .menu-gsk-zoomIn').on('click',function(e){
    var hlookat = krpano.get("view.hlookat");
    var vlookat = krpano.get("view.vlookat");
    var fov = krpano.get("view.fov") -7;
    krpano.call("lookto(" + hlookat + ", " + vlookat + ", " + (fov) + ", smooth(100,50,20), false, true, js(Krpano.onmousewheel(get(multireslevel))))");
    return false;
});




$('.zoom-out, .menu-gsk-zoomOut').on('mousedown touchstart', function(e) {
    timeout = setInterval(function(){
        var hlookat = krpano.get("view.hlookat");
        var vlookat = krpano.get("view.vlookat");
        var fov = krpano.get("view.fov") +7;
        krpano.call("lookto(" + hlookat + ", " + vlookat + ", " + (fov) + ", smooth(100,50,20), false, true, js(Krpano.onmousewheel(get(multireslevel))))");
    }, 500);

    return false;

});

$('.zoom-out, .menu-gsk-zoomOut').on('mouseup touchend',function(e){
    clearInterval(timeout);
});

$('.zoom-out, .menu-gsk-zoomOut').on('click', function(e) {
    var hlookat = krpano.get("view.hlookat");
    var vlookat = krpano.get("view.vlookat");
    var fov = krpano.get("view.fov") +7;
    krpano.call("lookto(" + hlookat + ", " + vlookat + ", " + (fov) + ", smooth(100,50,20), false, true, js(Krpano.onmousewheel(get(multireslevel))))");
    return false;
});
    
    
    
