 //  Call Ranking Screen


  window.gotohotspot = function(user_id, photo_id) {
      krpano.call("looktohotspot(brut" + user_id + ", 2, smooth(100,50,20)");

      if (currentUser.photo_id != photo_id) {
        krpano.call("loadscene(gigaphoto_" + photo_id +",null,MERGE,ZOOMBLEND(1,2,'easeInOutSin'));");     
        currentUser.photo_id = photo_id
    }
      krpano.call("looktohotspot(brut" + user_id + ", 0, smooth(100,50,20)");
        ranking.hide();
        overlay.hide();
        hamburger_cross();
        $('#wrapper').removeClass('toggled');

        setTimeout(function(){
          hotspotClicked(user_id);
        },1500);
        
  }

window.gotohotspot_wall = function (user_id, photo_id) {
      krpano.call("looktohotspot(brut" + user_id + ", 2, smooth(100,50,20)");

      if (currentUser.photo_id != photo_id) {
        krpano.call("loadscene(gigaphoto_" + photo_id +",null,MERGE,ZOOMBLEND(1,2,'easeInOutSin'));");     
        currentUser.photo_id = photo_id
    }
      krpano.call("looktohotspot(brut" + user_id + ", 0, smooth(100,50,20)");
        overlay.hide();
        setTimeout(function(){
          hotspotClicked(user_id);
        },1500);
        

  }




    $('a.ranking').click(function(event) {
      badges.hide();
      share.hide();
      contactus.hide();
      torcidometro.hide();
      tutorial.hide();
      card.hide();
      gigafotos.hide();
      friends.hide();

      $.ajax({
        url: '/api/v1/ranking/likes.json?tour_id='+tour_id + '&is_group=' + is_group,
        type: 'GET',
        success: function(data) {
          var base = data.cards;
          // console.log(base)
          
          
          if(base != 0) {
            base_photoId = base[0].photo_id
            base_userId = base[0].user.user_id
            if(base[0] != null) {
              $('#ranking .top-ranking .ranking-gold-name').text(base[0].user.name);
              $('#ranking .top-ranking .ranking-gold-visits').text(base[0].total);
              $('#ranking .top-ranking .ranking-gold-avatar').attr('src',base[0].user.image);
              $('#ranking .top-ranking .ranking-gold-cardlink').attr('href', 'javascript:gotohotspot(\'' + base_userId + '\',\'' + base_photoId + '\')');
            }
            if(base[1] != null) {
              base_photoId = base[1].photo_id
              base_userId = base[1].user.user_id
              $('#ranking .top-ranking .ranking-silver-name').text(base[1].user.name);
              $('#ranking .top-ranking .ranking-silver-visits').text(base[1].total);
              $('#ranking .top-ranking .ranking-silver-avatar').attr('src',base[1].user.image);
              $('#ranking .top-ranking .ranking-silver-cardlink').attr('href', 'javascript:gotohotspot(\'' + base_userId + '\',\'' + base_photoId + '\')');
            }
            if(base[2] != null) {
              base_photoId = base[2].photo_id
              base_userId = base[2].user.user_id
              $('#ranking .top-ranking .ranking-bronze-name').text(base[2].user.name);
              $('#ranking .top-ranking .ranking-bronze-visits').text(base[2].total);
              $('#ranking .top-ranking .ranking-bronze-avatar').attr('src',base[2].user.image);
              $('#ranking .top-ranking .ranking-bronze-cardlink').attr('href', 'javascript:gotohotspot(\'' + base_userId + '\',\'' + base_photoId + '\')');
            }
            

            var item = '';

             $.each(base, function (idx, obj) {
              if (idx > 2) {
                base_photoId = obj.photo_id
                base_userId = obj.user.user_id
                // console.log(base_photoId, base_userId)
                item += ('<tr>');
                item += ('<td scope="row">'+(idx+1)+'</td>');
                item += ('<td class="aligncenter"><a href="javascript:gotohotspot(\'' + base_userId + '\',\'' + base_photoId + '\')">'+obj.user.name+'</a></td>');
                item += ('<td class="aligncenter">'+obj.total+'</td>');
                item += ('</tr>');
                $(".ranking-list").html(item);
              }
             });
          }
        },
        beforeSend: function() { $('.overlay').show(); $('.loading').addClass('loading-animate'); },
        complete: function() {  
            $('.loading').removeClass('loading-animate');
            ranking.show();           
        }
      });


      return false;
    });
  