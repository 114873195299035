const firebase = require('firebase');
var login_waiting = localStorage.getItem('login_waiting');
const is_logged = localStorage.getItem('token');

window.krpano = document.getElementById("tourId");
window.photo_id = gon.photo_id;
if (is_logged) {
    $('#login').hide();
    $('.overlay').hide();
    $('.hamburger').show();
    krpano.set("autorotate.enabled", false);
}


if (login_waiting) {
    krpano.set("autorotate.enabled", false);
    $('#login').hide();
    $('.overlay').show();
    $('.loading').addClass('loading-animate');
    $('.hamburger').hide();
}



// current user

const brut_auth = JSON.parse(localStorage.getItem('brut_auth'));
window.currentUser = {}
if (brut_auth) {
    
  

    window.currentUser = JSON.parse(localStorage.getItem('current_user'));
    if (window.currentUser.checkin === true) {
        window.currentUser.cardUrl = window.origin + '?gigacard='+ window.currentUser.user_id;
        $('.makecheckin').hide();
        $('.make-checkin-faceApp').hide();
        $('#button-circle').css('background-color', '#fff');
        $('.mycheckin').show();
        $('.my-checkin-faceApp').show();
      } else {
        window.currentUser.checkin = false;
      }
    // Instagram 
    if (window.currentUser.provider == 'instagram.com') {
        // App.authentication.login(currentUser, tour_id, photo_id);  

        //window.currentUser.tour_id = tour_id
        window.currentUser.isAnonymous = false
       


        
        // add token to firebase
        firebase.auth().signInWithCustomToken(window.currentUser.baseToken).catch(function (da) {
            console.log(da, 'custom login')
            // ...  
        });

        $('.profile-usertitle-name').text(window.currentUser.name);
        $('.urlProfile').attr('href', window.currentUser.providerUrl);
        $('.urlProfile').removeClass('anonymous twitter google');
        $('.urlProfile').addClass('instagram');
        $('.profile').attr('src', window.currentUser.image);
        // $('[data-toggle="offcanvas"]').trigger('click');


    }

    
        // check if user has checked - canarysend

        $.ajax({
            url: '/api/v1/user/track/login.json',
            type: 'PUT',
            data: {
              "current_user": window.currentUser,
              "tour_id": gon.tour_id,
              "photo_id": window.photo_id
            },
            success: function(data) {
              if (data.checkin === true) {
                window.currentUser.checkin = true;
                window.currentUser.checkin_photo = data.checkin_photo;
                window.currentUser.cardUrl = window.origin + '?gigacard='+ window.currentUser.user_id;
                $('.makecheckin').hide();
                $('.make-checkin-faceApp').hide();
                $('#button-circle').css('background-color', '#fff');
                $('.mycheckin').show();
                $('.my-checkin-faceApp').show();
              } else {
                window.currentUser.checkin = false;
              }
              
              if(data.modalClient === false){
                $('#clientModal').modal('show')
              }
            }
        });




} else {
    

    firebase.auth().onAuthStateChanged(function (user) {
        

        if (user && user.isAnonymous == false) {
          krpano.set("autorotate.enabled", false);
            

            // console.log(user)
            // currentUser.tour_id = tour_id
            window.currentUser.photo_id = window.photo_id

            
            window.currentUser.token = user.refreshToken;
            var provider = user.providerData[0].providerId;
            window.currentUser.provider = user.providerData[0].providerId;
            window.currentUser.providerId = user.providerData[0].uid;
            window.currentUser.user_id = user.uid;
            window.currentUser.name = user.displayName;
            window.currentUser.email = user.email;
            window.currentUser.status = user.status;
            window.currentUser.isAnonymous = false

            // check if user has checked - canarysend

         

            // var hasVote = firebase.database().ref('quiz/' + tour_id + '/' + user.uid);

            // hasVote.once('value').then(function (data) {


            //     if (data.val() == null) {
            //         currentUser.hasVote = false
            //     } else {
            //         currentUser.hasVote = data.val().option
            //         $('#quiz .answer').hide();
            //         $('#quiz .question').show();
            //         $(".funkyradio input[name=" + currentUser.hasVote + "]").attr("checked", 'checked');
            //     }
            // });


            $('.profile-usertitle-name').text(user.displayName);

            // Facebook
            if (provider == 'facebook.com') {
                window.currentUser.email = user.providerData[0].email;
                photo = 'https://graph.facebook.com/' + currentUser.providerId + '/picture?width=500&height=500';
                urlProfile = 'https://facebook.com/' + user.providerData[0].uid;
                window.currentUser.image = photo
                $('.urlProfile').attr('href', urlProfile);
                $('.urlProfile').removeClass('anonymous twitter google instagram');
                $('.urlProfile').addClass('facebook');
                $('.profile').attr('src', photo);
            }

            // Twitter
            else if (provider == 'twitter.com') {
                window.currentUser.email = user.providerData[0].email;
                
                urlProfile = user.providerData[0].photoURL.replace('_normal', '');
                window.currentUser.image = urlProfile
                $('.urlProfile').attr('href', urlProfile);
                $('.urlProfile').removeClass('anonymous facebook google instagram');
                $('.urlProfile').addClass('twitter');
                $('.profile').attr('src', urlProfile);
            }
            // Google
            else if (provider == 'google.com') {
                photo = user.photoURL
                urlProfile = 'https://plus.google.com/' + user.providerData[0].uid;
                window.currentUser.image = photo
                $('.urlProfile').attr('href', urlProfile);
                $('.urlProfile').removeClass('anonymous facebook twitter instagram');
                $('.urlProfile').addClass('google');
                $('.profile').attr('src', photo);
            }


            //   db.collection("users").doc(user.uid).set({  
            //   user_id: user.uid,
            //   name: user.displayName,
            //   email: currentUser.email,

            //   // providerId: user.providerData[0].uid,
            //   // providerUrl: user.urlProfile

            // });

            // $('[data-toggle="offcanvas"]').trigger('click');
            // user_data = { "photo_id": currentUser.photo_id, "tour_id": tour_id, "user_id": user.uid, "name": user.displayName, "email": currentUser.email, "image": photo, "provider": provider, "providerId": user.providerData[0].uid, "providerUrl": urlProfile }


            // $.ajax({
            //     url: '/api/v1/user/signup/provider.json',
            //     type: 'POST',
            //     data: user_data,
            //     success: function (data) {
            //         // console.log('user update');
            //     }
            // });


            // console.log(currentUser.provider);
            // user.providerData.forEach(function (profile) {
            //   console.log("Sign-in provider: "+profile.providerId);
            //   console.log("  Provider-specific UID: "+profile.uid);
            //   console.log("  Name: "+profile.displayName);
            //   console.log("  Email: "+profile.email);
            //   console.log("  Photo URL: "+profile.photoURL);

            // });


            $.ajax({
                url: '/api/v1/user/track/login.json',
                type: 'PUT',
                data: {
                  "current_user": window.currentUser,
                  "tour_id": gon.tour_id,
                  "photo_id": window.photo_id
                },
                success: function(data) {
                  if (data.checkin === true) {
                    window.currentUser.checkin = true;
                    window.currentUser.checkin_photo = data.checkin_photo;
                    window.currentUser.cardUrl = window.origin + '?gigacard='+ window.currentUser.user_id;
                    $('.makecheckin').hide();
                    $('.make-checkin-faceApp').hide();
                    $('#button-circle').css('background-color', '#fff');
                    $('.mycheckin').show();
                    $('.my-checkin-faceApp').show();
                  } else {
                    window.currentUser.checkin = false;
                  }
                  if(data.modalClient === false){
                    $('#clientModal').modal('show')
                  }
                }
            });
            

            // App.authentication.login(currentUser, tour_id, photo_id);

        } else {
            $('.overlay').show();
            $('#login').show();
            $('.hamburger').hide();
          

            firebase.auth().signInAnonymously().catch(function (error) {
                console.log(error)
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
        
                // krpano.set("autorotate.enabled", false);
                // ...
            });
            window.currentUser.isAnonymous = true;
            window.currentUser.photo_id = window.photo_id;
            window.currentUser.user_id = user.uid;
            $('.link-pic-profile').addClass('anon-logged');
            // console.log('loguemex') 

            $('.profile-usertitle-name').text('LOGIN');
            $('.urlProfile').removeClass('facebook google twitter instagram');
            $('.urlProfile').addClass('anonymous');
            $('.profile').attr('src', 'https://images.gigafans.com/assets/player/anonymous.png');
            $('.urlProfile').removeAttr('href');
            $('.urlProfile').removeAttr('target');


            authdata = null



        }
        
    
       // console.log(currentUser, 'tchau');
        return currentUser;
    });
}


// localStorage.setItem('current_user', currentUser)
//  Create/update user


function createUser(user) {
    user_data = { "user_id": user.uid, "name": user.name, "email": user.email, "image": user.image, "provider_facebook": "true", "providerId": providerData[0].uid }

    $.ajax({
        url: '/api/v1/user/signup/provider.json',
        type: 'POST',
        data: user_data,
        success: function (data) {
            // console.log('user Created');
        }
    });
}

// console.log(currentUser)
//  login facebook

var facebook_provider = new firebase.auth.FacebookAuthProvider();
facebook_provider.addScope('public_profile')
facebook_provider.addScope('email')
facebook_provider.addScope('user_likes')
//facebook_provider.addScope('user_friends')

// https://developers.facebook.com/docs/facebook-login/permissions




$("#faceLogin").click(function () {
    krpano.set("autorotate.enabled", false);
    localStorage.setItem('login_waiting', true);
    firebase.auth().signInWithPopup(facebook_provider).then(function (result) {
        // Open Menu
        // $('[data-toggle="offcanvas"]').trigger('click');
        console.log(result)
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        // ... 



    }).catch(function (error) {
        console.log(error)
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
    });

});



//  login Twitter

var twitter_provider = new firebase.auth.TwitterAuthProvider();


$("#twitterLogin").click(function () {
    krpano.set("autorotate.enabled", false);
    localStorage.setItem('login_waiting', true);
    firebase.auth().signInWithPopup(twitter_provider).then(function (result) {
        // Open Menu
        // $('[data-toggle="offcanvas"]').trigger('click');
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var token = result.credential.accessToken;
        var secret = result.credential.secret;
        // The signed-in user info.
        var user = result.user;
        // ...
        
        urlProfile = user.providerData[0].photoURL.replace('_normal', '');
        user.user_id = user.uid;
        user.email = user.providerData[0].email;
        user.image = urlProfile;
        
        console.log(user)
        $('.profile-usertitle-name').text(user.displayName);
       
        $('.urlProfile').attr('href', urlProfile);
        $('.urlProfile').removeClass('anonymous facebook google instagram');
        $('.urlProfile').addClass('twitter');
        $('.profile').attr('src', urlProfile);
      
        
        $('#login').hide();
        $('.overlay').hide();
        $('.hamburger').show();
        krpano.set("autorotate.enabled", false);

        $.ajax({
            url: '/api/v1/user/track/login.json',
            type: 'PUT',
            data: {
              "current_user": user,
              "tour_id": gon.tour_id,
              "photo_id": window.photo_id
            },
            success: function(data) {
            
              if(data.modalClient === false){
                $('#clientModal').modal('show')
              }
            }
        });

    
    
    }).catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
    });

});

//  login Google

var google_provider = new firebase.auth.GoogleAuthProvider();
google_provider.addScope('profile');
google_provider.addScope('email');
$("#googleLogin").click(function () {

    krpano.set("autorotate.enabled", false);
    localStorage.setItem('login_waiting', true);
    firebase.auth().signInWithPopup(google_provider).then(function (result) {
        // Open Menu
        // $('[data-toggle="offcanvas"]').trigger('click');
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        console.log('djisj')
        $('#login').hide();
        $('.overlay').hide();
        $('.hamburger').show();
        krpano.set("autorotate.enabled", false);
        // ...
    }).catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
    });

});

//  login Instagram

var sessionStart = function () {
    alert('Session has started');
};
hello.init({
    instagram: '0c79602e62ca4a0499d5e5e7b9dcae36'
}, {
        scope: 'basic',
        redirect_uri: '/oauth/redirect',
        display: 'page',
    });


$("#instaLogin").click(function () {
    krpano.set("autorotate.enabled", false);
    localStorage.setItem('login_waiting', true);
    localStorage.setItem('tour_id', tour_id);

    hello('instagram').login().then(function () {
        hello.on('auth.login', sessionStart);
        // Open Menu
        // $('[data-toggle="offcanvas"]').trigger('click');
        alert('You are signed in to Instagram');
    }, function (e) {
        alert('Signin error: ' + e.error.message);
    });



});

//  Anonymous

$("#anonymousLogin").click(function () {
    krpano.set("autorotate.enabled", false);
    localStorage.setItem('token', 'signInAnonymously');
        window.currentUser.photo_id = window.photo_id;
        window.currentUser.isAnonymous = true
    $('.urlProfile').addClass('logoutButton');

    $('.link-pic-profile').addClass('anon-logged');

    firebase.auth().signInAnonymously().catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;

        // krpano.set("autorotate.enabled", false);
        // ...
    });

    $('.overlay').css('z-index', '999');

});



// Logout

$(".logoutButton").click(function () {

    firebase.auth().signOut().then(function () {
        localStorage.removeItem('token');
        localStorage.removeItem('current_user');
        localStorage.removeItem('brut_auth');
        currentUser = {}
        $('.hamburger').trigger('click');
        $('.overlay').show();
        $('#login').show();
        $('.hamburger').hide();

        $('.profile-usertitle-name').text('LOGIN');
        $('.avatar').attr('src', 'assets/player/anonymous.png');

        krpano.set("autorotate.enabled", true);
        $('#button-circle').css('background-color', '#005892');
        $('.mycheckin').hide();
        $('.makecheckin').show();

        // Sign-out successful.
    }).catch(function (error) {
        // An error happened.
    });

});


firebase.auth().getRedirectResult().then(function (result) {
    krpano.set("autorotate.enabled", false);
    $('.overlay').hide();
    $('.loading').removeClass('loading-animate');
    $('#login').hide();
    $('.hamburger').show();
    if (result.credential) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        localStorage.removeItem('login_waiting');
        localStorage.setItem('token', token);
        // ...
    }
    // The signed-in user info.
    var user = result.user;
}).catch(function (error) {
    // Handle Errors here.
    localStorage.removeItem('login_waiting');
    $('.loading').removeClass('loading-animate');
    $('#login').show();
    var errorCode = error.code;
    var errorMessage = error.message;
    // The email of the user's account used.
    var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    var credential = error.credential;
    // ...
});



// Modal Client Unilever




$('.close-modal-client').on('click', function(e) {

    let name = window.currentUser.name
    let parts = name.split(' ')
    let firstName = parts.shift(); 
    let lastName = parts.join(' ');


    var data = {
        "profile": {
          "header": {
            "isoLanguage": "pt",
            "isoCountry": "BR",
            "brandCode": "BH0432",
            "campaignId": "PN004388",
            "formType": "signUp",
            "entity": "PRM2.6",
            "origin": "carnavalsedaboom.gigafans.com"
          },
          "consumerIdentity": {
            "firstName": firstName,
            "lastName":  lastName,

          },
          "contactDetail": {
            "email": currentUser.email
          },
          "optInStatus": {
            "unileverEmailConsent": false,
            "legalAgeConsent": false
          }
        }
      }

    $.ajax({
        url: 'https://forms-us.unileversolutions.com/v3',
        type: "POST",
        headers: {
            'Origin':'carnavalsedaboom.gigafans.com',
            'x-api-key':'f43780f1-27ef-4f3e-9bd1-aaea4c2a7646',
            'Content-Type':'application/json'
        },
        dataType: 'json',
        data: JSON.stringify(data),
        success: function(msg) {
            console.log('eaee', msg)
            $.ajax({
                url: '/api/v1/user/track/modal.json',
                type: 'PUT',
                data: {
                "tour_id": gon.tour_id,
                "user_id": window.currentUser.user_id
                },
                success: function(data) {
                console.log(data)
                }
            });
          }
      });

});
    
    
$('.done-modal-client').on('click', function(e) {

    let name = window.currentUser.name
    let parts = name.split(' ')
    let firstName = parts.shift(); 
    let lastName = parts.join(' ');


    var data  = {
        "profile": {
          "header": {
            "isoLanguage": "pt",
            "isoCountry": "BR",
            "brandCode": "BH0432",
            "campaignId": "PN004388",
            "formType": "signUp",
            "entity": "PRM2.6",
            "origin": "carnavalsedaboom.gigafans.com"
          },
          "consumerIdentity": {
            "firstName": firstName,
            "lastName":  lastName,

          },
          "contactDetail": {
            "email": currentUser.email
          },
          "optInStatus": {
            "unileverEmailConsent": true,
            "legalAgeConsent": true
          }
        }
      }

    $.ajax({
        url: 'https://forms-us.unileversolutions.com/v3',
        type: "POST",
        headers: {
            'Origin':'carnavalsedaboom.gigafans.com',
            'x-api-key':'f43780f1-27ef-4f3e-9bd1-aaea4c2a7646',
            'Content-Type':'application/json'
        },
        dataType: 'json',
        data: JSON.stringify(data),
        success: function(msg) {
            console.log('eaee', msg)
            $.ajax({
                url: '/api/v1/user/track/modal.json',
                type: 'PUT',
                data: {
                "tour_id": gon.tour_id,
                "user_id": window.currentUser.user_id
                },
                success: function(data) {
                    console.log(data)
                }
            });
          }
      });

});
    


