// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, or any plugin's
// vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//

require("@rails/ujs").start()
require("jquery")

require("@rails/activestorage").start()
require("channels")

require("cj/setup")
window.hello = require("cj/hellojs")

require("cj/firebase/setup")
require("cj/firebase/cj")



require("cj/bootstrap.min")
require("cj/nouislider/distribute/nouislider")
window.moment = require("cj/moment/moment")
window.bootbox  = require("cj/bootbox")

window.firebase = require('firebase');
// var krpano = document.getElementById("tourId");
var login_waiting = localStorage.getItem('login_waiting');
const is_logged = localStorage.getItem('token');
window.tour_id = gon.tour_id;
window.is_group = gon.photos_group;

// var currentUser = {
//   cardUrl: "http://localhost:3000/#?card=343434",
//   email: "mninaut@yahoo.com.br",
//   isAnonymous: false,
//   name: "Matheus Ninaut",
//   provider: "twitter.com",
//   providerId: "26423695",
//   status: undefined,
//   user_id: "eNP2JaQm3tQPboNPhVVyMpUBVoI2"
// };
// console.log(currentUser)



require("cj/tourjs")
require("cj/watcher")

require("cj/card")
require("cj/message")
require("cj/wall")
require("cj/ranking")
require("cj/friends")




var hash = window.location.hash;



window.location.href.split('#')[0]

var ua = navigator.userAgent || navigator.vendor || window.opera;

    function isFacebookApp() {
        return (ua.indexOf("FBAN") > -1) && (ua.indexOf("FBAV") > -1);
    }

    if (isFacebookApp()) {
      $('#nav-menu-faceApp').show();
      $('#shadow-circle, #bottom-bar').hide();
    }


    // alert(navigator.userAgent.toLowerCase())
if (!is_logged) {
  $('.overlay').show();
  $('.hamburger').hide();
    if (login_waiting) {
      $('#login').hide();
      krpano.set("autorotate.enabled", true);
    } else {
      $('#login').show();
      krpano.set("autorotate.enabled", true);

   
    }
} 

window.trigger = $('.hamburger')
window.overlay = $('.overlay')
window.overlay_menu = $('.overlay_menu')
window.login = $('#login')
window.comments = $('#comments')
window.friends = $('#friends')
window.menuFeatures = $('#menu-features')
window.badges = $('#badges')
window.ranking = $('#ranking')
window.contactus = $('#contactus')
window.share = $('#share')
window.teams = $('#teams')
window.quiz = $('#quiz')
window.card = $('.card')
window.torcidometro = $('#torcidometro')
window.tutorial = $('#tutorial')
window.shareMoment = $('#sharemoment')
window.gigafotos = $('#gigafotos')
window.ecommerce = $('#ecommerce')
window.faq = $('#faq')


$(document).ready(function () {


  // Check Cookie
  checkCookie("gigafans");

  $('#termsModal').bind("contextmenu",function(e){
    return false;
  });

  $('#termsModal').bind('copy paste', function(e) {
      e.preventDefault();
  });

  // is mobile or desktop ?

  function isMobile() {
    var userAgent = navigator.userAgent.toLowerCase();
    if( userAgent.search(/(android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos)/i)!= -1 )
      return true;
  }

  if(isMobile()) {
    // mobile

  } else {
     // desktop
    document.querySelector('head').innerHTML += '<link rel="stylesheet" href="/assets/desktop.css" type="text/css"/>'
  }  

    isClosed = false;

    trigger.click(function() {
      hamburger_cross();      
    });
  
    
    $('[data-toggle="offcanvas"]').click(function () {
        $('#wrapper').toggleClass('toggled');
    });
     
  
  /*-------------------------------*/
  /*          Open Screens         */
  /*-------------------------------*/

    // Check-in FaceApp Screen
    $('#nav-menu-faceApp a.make-checkin-faceApp').click(function(event) {
      // Anonymous Login
      if (currentUser.isAnonymous) {
        overlay.show();
        login.show();
        trigger.removeClass('is-closed');
        isClosed = false;
        return false;
      } else {
        
        $('img.aim').show();
        $('a.close-checkin').show();
        $('.confirm-checkin-faceApp').show();
        return false;
      }
     
    });

    // Nav Menu GSK

    $('a.menu-gsk-contactus').click(function(event) {
      badges.hide();
      ranking.hide();
      share.hide();
      torcidometro.hide();
      tutorial.hide();
      card.hide();
      friends.hide();
      gigafotos.hide();
      overlay.show();
      contactus.show();
      return false;
    });
  
    // Call Comments Screen
    $('.action-comments').click(function(event) {

      get_messages = { "user_id": currentUser.id, "tour_id": tour_id, "type": "wall"}
      $.ajax({
        url: '/api/v1/message.json?is_group=' + is_group,
        type: 'GET',
        data: get_messages,
        success: function(data) {
          updateWall(data);       
        },
        beforeSend: function() { $('.overlay').show(); $('.loading').addClass('loading-animate'); },
        complete: function() { 
          $('.overlay').hide(); 
          $('.loading').removeClass('loading-animate'); 
          comments.show();
        }
      });

      return false;
    });  
  

  
    // Call Badges Screen
    $('a.badges').click(function(event) {
      // ranking.hide();
      // share.hide();
      // contactus.hide();
      // torcidometro.hide();
      // tutorial.hide();
      // card.hide();
      // gigafotos.hide();
      // badges.show();
      return false;
    });
  
   
    // Call Contact us Screen
    $('a.contactus').click(function(event) {
      badges.hide();
      ranking.hide();
      share.hide();
      torcidometro.hide();
      tutorial.hide();
      card.hide();
      friends.hide();
      gigafotos.hide();
      contactus.show();
      return false;
    });

  
    // Call Share Screen
    $('a.share').click(function(event) {
      badges.hide();
      ranking.hide();
      contactus.hide();
      torcidometro.hide();
      tutorial.hide();
      card.hide();
      gigafotos.hide();
      friends.hide();
      share.show();

    if(currentUser.provider == 'facebook.com') {
      $('a.s_facebook').show();
      $('a.s_twitter').hide();
      $('a.s_google').hide();

      $('#sharemoment .btn-facebook').show();
      $('#sharemoment .btn-twitter').hide();
      $('#sharemoment .btn-google').hide();
    } else if(currentUser.provider == 'twitter.com') {
      $('a.s_twitter').show();
      $('a.s_facebook').hide();
      $('a.s_google').hide();
      $('#sharemoment .btn-twitter').show();
      $('#sharemoment .btn-facebook').hide();
      $('#sharemoment .btn-google').hide();
    } else if(currentUser.provider == 'google.com'){
      $('a.s_google').show();
      $('a.s_twitter').hide();
      $('a.s_facebook').hide();
      $('#sharemoment .btn-google').show();
      $('#sharemoment .btn-facebook').hide();
      $('#sharemoment .btn-twitter').hide();
    }

      return false;
    });

    // Call +Gigafotos
    $('.gigafotos-btn-link.gigafotos').click(function(event) {
      badges.hide();
      ranking.hide();
      share.hide();
      torcidometro.hide();
      tutorial.hide();
      card.hide();
      friends.hide();
      gigafotos.show();
      return false;
    });
  
    // Call Teams Screen
    $('a.teams').click(function(event) {
      teams.show();
      overlay_menu.show();
      return false;
    });

    // Call Quiz Screen
    $('a.quiz').click(function(event) {
      quiz.show();
      overlay_menu.show();
      return false;
    });
  
    // Call Torcidometro Screen
    $('a.torcidometro').click(function(event) {
      badges.hide();
      ranking.hide();
      share.hide();
      contactus.hide();
      tutorial.hide();
      card.hide();
      friends.hide();
      gigafotos.hide();
      // Hotspot count

      $.ajax({
        url: '/api/v1/tour/card/total_cards.json?tour_id='+ tour_id + '&is_group=' + is_group,
        success: function (data) {
          $('#qtd_checkin').text(data);

        }
      });

      
      torcidometro.show();
      return false;
    });

    // Call Tutorial Screen
    $('a.help').click(function(event) {
      badges.hide();
      ranking.hide();
      share.hide();
      contactus.hide();
      torcidometro.hide();
      card.hide();
      gigafotos.hide();
      tutorial.show();
      return false;
    });

    // Call FAQ Screen
    $('a.faq').click(function(event) {
      badges.hide();
      ranking.hide();
      share.hide();
      contactus.hide();
      torcidometro.hide();
      card.hide();
      gigafotos.hide();
      faq.show();
      return false;
    });

    // Call E-commerce Screen
    $('.cta-ecommerce').click(function(event) {
      badges.hide();
      ranking.hide();
      share.hide();
      contactus.hide();
      torcidometro.hide();
      card.hide();
      gigafotos.hide();
      tutorial.hide();
      ecommerce.show();
      return false;
    });


    // Call Share Moment
    $('.open-sharemoment').click(function(event) {
      console.log(event)
      if(currentUser.provider == 'facebook.com') {
        $('#sharemoment .btn-facebook').show();
        $('#sharemoment .btn-twitter').hide();
        $('#sharemoment .btn-google').hide();
      } else if(currentUser.provider == 'twitter.com') {
        $('#sharemoment .btn-twitter').show();
        $('#sharemoment .btn-facebook').hide();
        $('#sharemoment .btn-google').hide();
      } else if(currentUser.provider == 'google.com'){
        $('#sharemoment .btn-google').show();
        $('#sharemoment .btn-facebook').hide();
        $('#sharemoment .btn-twitter').hide();
      }
      else if (currentUser.isAnonymous == true) {
        $('.btn-anonymous').show();
        $('#sharemoment .btn-facebook').hide();
        $('#sharemoment .btn-twitter').hide();
        $('#sharemoment .btn-google').hide();
      }

      shareMoment.show();

      return false;
    });
  
  
  /*-------------------------------*/
  /*          select Team          */
  /*-------------------------------*/
  
  $('#teams ul li a').click(function(event) {

    my_team = $(this).attr('id');
    $('.teams > span').hide();
  
    $('.teams > img').remove();
    $('.teams').append("<img id='myteam' src='assets/player/teams/"+my_team+".png'/>");
  
    teams.hide();
    overlay_menu.hide();
  
    return false;
  });

  

  /*-------------------------------*/
  /*         Photo Share           */
  /*-------------------------------*/

  $("a.s_twitter").on("click", function(e) {
    e.preventDefault();

    windowPopup($(this).attr("href"), 500, 300);
  });

  $("a.s_facebook").on("click", function(e) {
    e.preventDefault();

    windowPopup($(this).attr("href"), 500, 300);
  });

  $("a.s_google").on("click", function(e) {
    e.preventDefault();

    windowPopup($(this).attr("href"), 500, 300);
  });
  
// Remove the ugly Facebook appended hash
// <https://github.com/jaredhanson/passport-facebook/issues/12>
if (window.location.hash && window.location.hash === "#_=_") {
  // If you are not using Modernizr, then the alternative is:
  //   `if (window.history && history.replaceState) {`
  if (Modernizr.history) {
    window.history.replaceState("", document.title, window.location.pathname);
  } else {
    // Prevent scrolling by storing the page's current scroll offset
    var scroll = {
      top: document.body.scrollTop,
      left: document.body.scrollLeft
    };
    window.location.hash = "";
    // Restore the scroll offset, should be flicker free
    document.body.scrollTop = scroll.top;
    document.body.scrollLeft = scroll.left;
  }
}
  

  /*-------------------------------*/
  /*         Close Screens         */
  /*-------------------------------*/
  
    $('.close-window').click(function() {
      var _screen = $(this).parent().parent().attr('data-id');
  
      switch(_screen) {
        case 'comments':
          closeWindow(comments);
          break;
        case 'friends':
          closeWindow(friends);
          break;
        case 'badges':
          closeWindow(badges);
          break;
        case 'ranking':
          closeWindow(ranking);
          break;
        case 'contactus':
          closeWindow(contactus);
          break;
        case 'share':
          closeWindow(share);
          break;
        case 'card':
          closeWindow(card);
          $('.card').removeAttr('id');
          overlay.hide();
          break;
        case 'torcidometro':
          closeWindow(torcidometro);
          break;
        case 'sharemoment':
          closeWindow(shareMoment);
          break;
          case 'gigafotos':
          closeWindow(gigafotos);
          break;
          case 'tutorial':
          closeWindow(tutorial);
          break;
          case 'ecommerce':
          closeWindow(ecommerce);
          break;
          case 'faq':
          closeWindow(faq);
          break;
      }
      
      return false;
    });

    $('.close-login').click(function(event) {
      login.hide();
      overlay.hide();
      trigger.show();
      trigger.addClass('is-closed');
      return false;
    });
  
    $('.overlay_menu').click(function(event) {
      teams.hide();
      quiz.hide();
      // $('#quiz .answer').hide();
      // $('#quiz .question').show();
      $(this).hide();
      overlay.show();
    });
  
  
    // Function Close Window
  
    function closeWindow(window) {
      window.addClass('animated slideOutDown');
      setTimeout(function(){
        window.hide();        
        window.removeClass('slideOutDown');
      },1000); 

    }

  /*-------------------------------*/
  /*            Loading            */
  /*-------------------------------*/

  function loading(window, theme) {
    $('.loading').addClass('loading-animate '+theme);
    setTimeout(function(){
     $('.loading').removeClass('loading-animate '+theme);
     closeWindow(window);
    },2000);
  }

  /*-------------------------------*/
  /*        Send Contact Us        */
  /*-------------------------------*/
  
  $('.send-contact').click(function(event) {

    var name = $("#contactus input[name='name']").val();
    var email = $("#contactus input[name='email']").val();
    var msg = $("#contactus textarea[name='msg']").val();

    if(name != '' && email != '' & msg != '' ) {
      $.ajax({
          url: '/api/v1/user/signup/contact.json?name='+name+'&email='+email+'&msg='+msg+'&tour_id='+tour_id,
          type: 'POST',
          success: function(data) {
              
          },
          beforeSend: function() { $('.overlay').show(); $('.loading').addClass('loading-animate'); },
          complete: function() { 
            $('.overlay').hide(); 
            $('.loading').removeClass('loading-animate'); 
            
            $('#contactus input').val('');
            $('#contactus textarea').val('');

            if($('body').hasClass('corinthians')) {
                $('#contactus a.send-contact').css({
                backgroundColor: '#2b3bad',
                color: '#fff',
                fontSize: '15px',
                lineHeight: '36px'
              });
              
            } else {

            $('#contactus a.send-contact').css({
              backgroundColor: '#00E676',
              color: '#fff',
              fontSize: '15px',
              lineHeight: '36px'
            });
          }
            $('#contactus a.send-contact').text('Mensagem Enviada com sucesso!');
            setTimeout(function(){
              closeWindow(contactus);
            },3000);
          } 
      });
    }

      return false;
  });


  /*-------------------------------*/
  /*        SWITCH ON/OFF          */
  /*-------------------------------*/

  $('#onOff').click(function(){
    if($('#onOff').is(':checked') == false) {
      $('#onOff').removeAttr('checked');
      // HIDE HOTSPOT

      krpano.call("hide_hotspots");
    }
    else {
      $('#onOff').attr('checked');
      // SHOW HOTSPOT
      krpano.call("show_hotspots");
    }
  });


  /*-------------------------------*/
  /*        Show Snapshot          */
  /*-------------------------------*/

  $('#snapButton').click(function () {
    krpano.call("my_snapshot");
  });


  // My Crush 

  $(".heart").on('click touchstart', function(){
    $(this).toggleClass('is_animating');
  });

  $('.my-crush').click(function(event) {

    _cardID = $(this).parent().parent().parent().attr('id');
    _cardName = 'brut'+_cardID;

    // $.ajax({
    //     url: '',
    //     type: 'POST',
    //     success: function(data) {

    //     },
    //     beforeSend: function() { },
    //     complete: function() { 
    //       $('.loading').removeClass('loading-animate');
    //     } 
    // });
            
    return false;    
    
  });

// Ranking + Views - /api/v1/ranking/views.json?tour_id=8    
  
}); // End document.ready


// Call Login with Anonymous login

$(document).on('click','.anon-logged',function(event) {
    $('.hamburger').trigger('click');
    $('.overlay').show();
    login.show();
    $('.hamburger').hide();
    return false;
});

// CTA LOGIN

$('.cta-login-sharemoment').click(function(event) {
    shareMoment.hide();
    card.hide();
    $('.hamburger').hide();
    $('.overlay').show();
    login.show();
    return false;
});

  

/*-------------------------------*/
/*       COPY TO CLIPBOARD       */
/*-------------------------------*/

  function copyToClipboard(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $('.copied').addClass('animated fadeIn');
    $temp.remove();
  }

  


  window.hamburger_cross = function() {
  
    if (isClosed == true) {  
      overlay.hide();
      trigger.removeClass('is-open');
      trigger.addClass('is-closed');
      isClosed = false;

      badges.hide();
      ranking.hide();
      share.hide();
      contactus.hide();
      torcidometro.hide();
      tutorial.hide();
      ecommerce.hide();

    } else {   
      overlay.show();
      trigger.removeClass('is-closed');
      trigger.addClass('is-open');
      $('#shadow-circle').css('z-index', '102');
      isClosed = true;
    }
}

window.closeWindow_Menu = function (window) {
      window.addClass('animated slideOutDown');
      setTimeout(function(){
        window.hide();
        window.removeClass('slideOutDown');
      },1000);
      if (isClosed == false) {

      } else {
        $('[data-toggle="offcanvas"]').trigger('click');
      }

      

    }

// Cookie

window.setCookie = function() {
  $('.cookie-container').hide();
  var d = new Date();
  let cname = "gigafans";
  let cvalue = "giga-cookie";
  let exdays = 30;

  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires=" + d.toGMTString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

window.getCookie = function(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

window.checkCookie = function() {
  var user = getCookie("gigafans");
  if (user != "") {
    $('.cookie-container').hide();
  } else {
    $('.cookie-container').show();
  }
}

