$('.send-comment').click(function(event) {
  event.preventDefault();
    if(currentUser.isAnonymous == true) {
      $('.overlay').show();
      $('.overlay').css('z-index', '999999999');
      $('#login').show();
    }

    var content = $('#comments input.content').val();   
    if(content != '' && currentUser.isAnonymous == false) {
      $.ajax({
          url: '/api/v1/message.json?tour_id='+tour_id+'&user_id='+currentUser.user_id+'&type=wall&content='+content +'&is_group='+is_group  ,
          type: 'PUT',
          success: function(data) {
            if (data.user.checking) {
              $('.wall-message').prepend('<li class="animated fadeIn"><img class="avatar img-responsive" src="' + data.user.image + '"><a href="javascript:gotohotspot(\'' + data.user.user_id + '\',\'' + data.user.checking + '\')">' + data.user.name + '</a><p>' + data.content + '</p><p class="pull-right">' + data.created + '</p></li>');
            } else {
              $('.wall-message').prepend('<li class="animated fadeIn"><img class="avatar img-responsive" src="' + data.user.image + '"><a href="' + data.user.providerUrl + '">' + data.user.name + '</a><p>' + data.content + '</p><p class="pull-right">' + data.created + '</p></li>');
            }
     
          },
          beforeSend: function() { $('.overlay').show(); $('.loading').addClass('loading-animate'); },
          complete: function() { 
            $('.overlay').hide(); 
            $('.loading').removeClass('loading-animate'); 
            $('#comments input.content').val(''); 
            $('#comments i.fa-paper-plane').removeClass('animated wobble'); 
            $('#comments .content').attr('placeholder', 'Escreva sua mensagem...');
          
          }
        });
    } else {
          $('#comments .content').attr('placeholder', 'Digite alguma mensagem!');
          $('#comments i.fa-paper-plane').addClass('animated wobble');
      }

    return false;
  });



window.updateWall = function(data) {


    var messages = [];
    
    if (data.messages == null) {
    	$(".wall-message").html('');
    	$('.wall-message').append('<li class="empty"><img src="assets/player/no_message.png">Você ainda não possui mensagens!</li>');
    }
    else {
	    $.each(data.messages, function (idx, obj) {    
        messages += ('<li>');
	      messages += ('<img class="avatar img-responsive" src="' + obj.user.image + '">');                            
        if (obj.user.checking) {
          messages += ('<a href="javascript:gotohotspot_wall(\''+obj.user.user_id+'\',\''+obj.user.checking+'\')">' + obj.user.name + '</a>');
        }else{
          messages += ('<a href="'+obj.user.providerUrl+'" target=_"blank">' + obj.user.name + '</a>');
        }
         messages += ('<p>' + obj.content + '</p>');
         messages += ('<p class="pull-right">' + obj.created + '</p>');
        //  messages += ('<p class="actions"><a href="#">Responder</a></p>');
	       // messages += ('<div class="comment-anwser"><input type="text" placeholder="Escreva uma resposta..."/></div>');
         messages += ('</li>');
	    });
	   	$(".wall-message").html(messages);
      $('.wall-message li:last-child').addClass('animated fadeIn');
 	}


}
