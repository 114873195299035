    // Call Friends Screen
    $('a.friends').click(function(event) {
      badges.hide();
      ranking.hide();
      share.hide();
      contactus.hide();
      torcidometro.hide();
      tutorial.hide();
      card.hide();
      gigafotos.hide();
      friends.show();

      
        user_data = { "token": currentUser.token}
      $.ajax({
            url: '/api/v1/friends/instagram.json',
            type: 'GET',
            data: user_data,
            success: function (data) {
                
                updateFriends(data)
                
            }
        });

      return false;
    });



function updateFriends(data) {


    var friends = [];
        
        if (data == null) {
            // $(".wall-message").html('');
            // $('.wall-message').append('<li class="empty"><img src="assets/player/no_message.png">Você ainda não possui mensagens!</li>');
            console.log('none')
        }
        else {
            $.each(data, function (obj, user) {    
                console.log(user.profile_picture)
            data += ('<div class="col-xs-4">');
            data += ('<img class="avatar img-responsive" src="' + user.profile_picture + '">');                            
            data += ('<h5>' + user.username + '</h5>');
            data += ('<p class="pull-right">' + user.created + '</p>');
            //  messages += ('<p class="actions"><a href="#">Responder</a></p>');
            // messages += ('<div class="comment-anwser"><input type="text" placeholder="Escreva uma resposta..."/></div>');
            data += ('</div>');
            });
            $(".loadFriend").html(data);
        // $('.wall-message li:last-child').addClass('animated fadeIn');
        }


}

 