


window.openCard = function(data) {
    event.preventDefault();
    
  var hidden = 1;
    $('.card').attr('id', data.card_id);
    card_id = '#' + data.card_id
    $('.card .header').css('background-image', 'url(' + data.user.image + ')');

    if(currentUser.user_id == data.card_id) {
        $('.remove-card').show();
        // $('.my-crush').hide();
    } else {
        $('.remove-card').hide();
        // $('.my-crush').show();

    }

    if(tour_id == '77'){
        snapUrl = 'https://images.gigafans.com/snapshot/' + tour_id + '/' + data.card_id + '/'+tour_id+'_'+currentUser.photo_id+'_'+data.card_id+'.png';    
    }


    // console.log(card_id)

    $(card_id + ' .profile').attr('src', data.user.image);
    $(card_id + ' .name').text(data.user.name);

    var provider = data.user.provider;
    if(provider == 'twitter.com') {
    	// twitter
    	$(card_id + ' .urlProfile').removeClass('facebook google instagram');
        $(card_id + ' .urlProfile').addClass('twitter');
        $(card_id + ' .urlProfile').attr('href', data.user.providerUrl);
        $(card_id + ' .urlProfile').attr('target', '_blank');
    } else if (provider == 'facebook.com') {
    	// facebook
    	$(card_id + ' .urlProfile').removeClass('twitter google instagram');
        $(card_id + ' .urlProfile').addClass('facebook');
        $(card_id + ' .urlProfile').attr('href', data.user.providerUrl);
        $(card_id + ' .urlProfile').attr('target', '_blank');
    }
    else if (provider == 'google.com') {
    	// google
    	$(card_id + ' .urlProfile').removeClass('facebook twitter instagram');
        $(card_id + ' .urlProfile').addClass('google');
        $(card_id + ' .urlProfile').attr('href', data.user.providerUrl);
        $(card_id + ' .urlProfile').attr('target', '_blank');
    }
    else if (provider == 'instagram.com') {
    	// instagram
    	$(card_id + ' .urlProfile').removeClass('facebook twitter google');
        $(card_id + ' .urlProfile').addClass('instagram');
        $(card_id + ' .urlProfile').attr('href', data.user.providerUrl);
        $(card_id + ' .urlProfile').attr('target', '_blank');
    }

    //status

        $(card_id + " #total_views").text(data.status.total_views);
        $(card_id + " #total_comments").text(data.status.total_comments);
        $(card_id + " #total_likes").text(data.status.total_likes);

    // Get Messages

    var messages = [];
    
    if (data.messages == null) {
    	$(card_id + " .card-message").html('');
    	$(card_id +' .card-message').append('<li class="empty"><img src="https://images.gigafans.com/assets/player/no_message.png">Você ainda não possui mensagens!</li>');
    }
    else {
	    $.each(data.messages, function (idx, obj) {  
        // console.log(obj);                                
	       messages += ('<li>');
	       messages += ('<img class="avatar img-responsive" src="' + obj.user.image + '">');
	       messages += ('<a href="'+obj.user.providerUrl+'" target=_"blank">' + obj.user.name + '</a>');
	       messages += ('<p>' + obj.content + '</p>');
	       messages += ('<p class="pull-right">' + obj.created + '</p>');
           messages += ('</li>');
	    });
	   	$(card_id +" .card-message").html(messages);
 	}


    if(data.ilike == true ) {
        $('#like i').addClass('fa-thumbs-up');
        $('#like i').removeClass('fa-thumbs-o-up');
    } else {
        $('#like i').addClass('fa-thumbs-o-up');
        $('#like i').removeClass('fa-thumbs-up');
    }
} 
    

window.updateCard = function (data, card_id) {
    card_id = '#' + card_id
    
    // status hotspot
// console.log(data)
        $(card_id + " #total_views").text(data.status.total_views);
        $(card_id + " #total_comments").text(data.status.total_comments);
        $(card_id + ' #total_likes').text(data.status.total_likes);

    // Get Messages from cards 

        var messages = '';
        
        if (data.messages == null) {
            $(card_id + " .card-message").html('');
        }
        else {
            $.each(data.messages, function (idx, obj) {                                  
            messages += ('<li>');
            messages += ('<img class="avatar img-responsive" src="' + obj.user.image + '">');
            messages += ('<a href="'+obj.user.providerUrl+'" target=_"blank">' + obj.user.name + '</a>');
            messages += ('<p>' + obj.content + '</p>');
            messages += ('<p class="pull-right">' + obj.created + '</p>');
            messages += ('</li>');
            });
            $(card_id + " .card-message").html(messages);
        }
}



$('.send-message').click(function(event) {
    event.preventDefault();
    var IDhotspot = $(this).parent().parent().attr('id');
    var content = $('input.message').val();
    if(currentUser.isAnonymous == false) {
        sendMessage(currentUser.user_id, IDhotspot, content);
    }
    else {
        $('.card footer input').attr('placeholder', 'Digite alguma mensagem!');
        $('.card footer i.fa-paper-plane').addClass('animated wobble');
    }
});

$('#like').click(function(event) {
    event.preventDefault();

    var IDhotspot = $(this).parent().parent().parent().parent().parent().attr('id');
    $('#like i').toggleClass('fa-thumbs-o-up fa-thumbs-up');

    sendLike(currentUser.user_id, IDhotspot);
});
    
// Send Like

window.sendLike = function (user, id) {

    $.ajax({
        url: '/api/v1//tour/card/cards_like.json?tour_id=' + tour_id + '&user_id=' + user + '&card_id=' + id + '&photo_id=' + photo_id  ,
        type: 'POST',
        success: function (data) {

            $('#total_likes').text(data)
            return false;
        },
        beforeSend: function () { },
        complete: function () {

        }
    });

    return false;
}




// Remove Card 

  $('.remove-card').click(function(event) {

    _cardID = currentUser.user_id
    _cardName = 'brut'+_cardID;

    bootbox.confirm({
      message: "Tem certeza que deseja remover sua marcação?",
      buttons: {
          confirm: {
              label: 'Sim',
              className: 'btn-success'
          },
          cancel: {
              label: 'Não',
              className: 'btn-danger'
          }
      },
      callback: function (result) {
          if (result == true) {
            // remove card
            $.ajax({
                url: '/api/v1/tour/card.json?tour_id=' + tour_id + '&photo_id=' + currentUser.photo_id + '&card_id=' + _cardID + '&user_id=' + currentUser.user_id,
                type: 'DELETE',
                success: function(data) {
                    krpano.call("removehotspot("+ _cardName + ")");
                    $('.confirm-check').removeClass('loading-animate');
                    $('.confirm-check').removeClass('flipOutX');
                    $('img.aim').removeClass('animated bounceOut');
                    $('.mycheckin').hide();
                    $('.my-checkin-faceApp').hide();
                    $('.makecheckin').show();
                    $('.make-checkin-faceApp').show();
                    $('#button-circle').css('background-color', '#005892');
                },
                beforeSend: function() { $('.overlay').show(); $('.loading').addClass('loading-animate'); },
                complete: function() { 
                    window.currentUser.checkin = null
                    window.currentUser.checkin_photo = null
                  $('.loading').removeClass('loading-animate');

                  $('#'+_cardID).hide();
                  overlay.hide(); 
                } 
            });
            
          }
      }
    });

    return false;
  });