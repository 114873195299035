// Var tourjs


var krpano = document.getElementById("tourId");
var hlookat = krpano.get("view.hlookat");
var vlookat = krpano.get("view.vlookat");
var fov = krpano.get("view.fov");
var distortion = krpano.get("view.distortion");
var hotspot_count = krpano.get('hotspot.count');
// demo photo
var tour_id = gon.tour_id
var photo_id = gon.photo_id;
var is_group = gon.photos;
// examples (called by the button onclick events)
// console.log(tour_id)
window.gigacard = $('.card').data('param');
var _qtd = gigacard.length;
var _cardID = gigacard.slice(0, -1);
var _tourID = gigacard.slice(_qtd - 1);
 
// console.log(tour_id)


// direct access to attribute

// $("#snapButton").click(function () {
    
//     krpano.call("makescreenshot");
//     getSnap = $('#getSnap').attr('src');
//     // console.log(getSnap)
//     $('#sharemoment').show();

// });


// is_group ?

// Buttons
// dev

// if (tour_id == 70) {
//     var is_group = ['26', '21']
//     // $('.bottom-menu').append("<a href='/201710072' style='line-height: 51px;'>Foto 2</a>");
//     // $('.gigafotos-faceApp').attr("href", "/201710072");
//     // $('.gigafotos-faceApp span').text("Foto 2");
// } 


// VillaMix
if (tour_id == 7) {
    var is_group = ['7', '8']
    $('.bottom-menu').append("<a href='/201710072' style='line-height: 51px;'>Foto 2</a>");
    $('.gigafotos-faceApp').attr("href", "/201710072");
    $('.gigafotos-faceApp span').text("Foto 2");
} 
// else if (tour_id == 8) {
//     var is_group = ['7', '8']
//     $('.bottom-menu').append("<a href='/20171007' style='line-height: 51px;'>Foto 1</a>");
//     $('.gigafotos-faceApp').attr("href", "/20171007");
//     $('.gigafotos-faceApp span').text("Foto 1");

// } 
// CBF
else if (tour_id == 9) {
    var is_group = ['9', '10']
    $('.bottom-menu').append("<a href='/201710042' style='line-height: 51px;'>Leste</a>");
    $('.gigafotos-faceApp').attr("href", "/201710042");
    $('.gigafotos-faceApp span').text("Leste");
} else if (tour_id == 10) {
    var is_group = ['9', '10']
    $('.bottom-menu').append("<a href='/20171004' style='line-height: 51px;'>Oeste</a>");
    $('.gigafotos-faceApp').attr("href", "/20171004");
    $('.gigafotos-faceApp span').text("Oeste");
}
// Fernando e Sorocaba
else if (tour_id == 11) {
    var is_group = ['11', '16']
    $('.bottom-menu').append("<a href='/201710102' style='line-height: 51px;'>Foto 2</a>");
    $('.gigafotos-faceApp').attr("href", "/201710102");
    $('.gigafotos-faceApp span').text("Foto 2");
} else if (tour_id == 16) {
    var is_group = ['11', '16']
    $('.bottom-menu').append("<a href='/20171010' style='line-height: 51px;'>Foto 1</a>");
    $('.gigafotos-faceApp').attr("href", "/20171010");
    $('.gigafotos-faceApp span').text("Foto 1");
}

// NBB
else if (tour_id == 17) {
    var is_group = ['17', '18']
    $('.bottom-menu').append("<a href='/201711042' style='line-height: 51px;'>Foto 2</a>");
    $('.gigafotos-faceApp').attr("href", "/201711042");
    $('.gigafotos-faceApp span').text("Foto 2");
} else if (tour_id == 18) {
    var is_group = ['17', '18']
    $('.bottom-menu').append("<a href='/20171104' style='line-height: 51px;'>Foto 1</a>");
    $('.gigafotos-faceApp').attr("href", "/20171104");
    $('.gigafotos-faceApp span').text("Foto 1");
}

// CIS
else if (tour_id == 19) {
    var is_group = ['19']
    
} 
else if (tour_id == 55) {
    var is_group = ['55']
    
} 


// Corinthians
else if (tour_id == 20) {
    var is_group = ['20', '21','22']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 21) {
    var is_group = ['53', '54']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
// São Paulo
else if (tour_id == 23 || tour_id == 25) {
    var is_group = ['23', '25']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");

}
// Revellion Rio 2018 ( one word)
else if (tour_id == 70) {
    var is_group = ['27', '28', '29', '30', '31', '32']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");


}
// Encontro do Samba ( one word)
else if (tour_id == 71) {
    var is_group = ['33', '34', '35', '36']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");


}
// Florida Cup
else if (tour_id == 72) {
    var is_group = ['38', '39F']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}

else if (tour_id == 73) {
    var is_group = ['41']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 74) {
    var is_group = ['43']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 75) {
    var is_group = ['44']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}

else if (tour_id == 76) {
    var is_group = ['47']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}

else if (tour_id == 78) {
    var is_group = ['9', '10', '14', '16', '20', '21', '22']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Photos");
}

else if (tour_id == 79) {
    var is_group = ['23','24']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Photos");
}
else if (tour_id == 80) {
    var is_group = ['49', '50']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}

else if (tour_id == 81) {
    var is_group = ['51']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 82) {
    var is_group = ['52']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 83) {
    var is_group = ['25', '26']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 84) {
    var is_group = ['27', '28']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 290) {
    var is_group = ['290']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 830) {
    var is_group = ['310', '320']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 837) {
    var is_group = ['370', '380','390', '400']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 707) {
    var is_group = ['330', '340']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 708) {
    var is_group = ['350', '360']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}

else if (tour_id == 86) {
    var is_group = ['300']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}

else if (tour_id == 87) {
    var is_group = ['60']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}

else if (tour_id == 88) {
    var is_group = ['59']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 89) {
    var is_group = ['66', '64', '65']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 90) {
    var is_group = ['67']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 91) {
    var is_group = ['68']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 92) {
    var is_group = ['81', '82']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 93) {
    var is_group = ['84', '83']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 94) {
    var is_group = ['85']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 95) {
    var is_group = ['86']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 96) {
    var is_group = ['87', '88']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 97) {
    var is_group = ['89','90']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 98) {
    var is_group = ['91']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 99) {
    var is_group = ['92','93']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 100) {
    var is_group = ['94','95']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 101) {
    var is_group = ['97']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 102) {
    var is_group = ['98']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 103) {
    var is_group = ['99']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 104) {
    var is_group = ['100', '102']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 105) {
    var is_group = ['102']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 108) {
    var is_group = ['103', '104']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 109) {
    var is_group = ['106']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 110) {
    var is_group = ['107']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 111) {
    var is_group = ['108','109']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 112) {
    var is_group = ['112']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 113) {
    var is_group = ['113']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 114) {
    var is_group = ['115']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 115) {
    var is_group = ['116']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 116) {
    var is_group = ['117']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 117) {
    var is_group = ['118','119']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}

else if (tour_id == 118) {
    var is_group = ['120', '121']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 119) {
    var is_group = ['122', '123']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 120) {
    var is_group = ['124']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 121) {
    var is_group = ['125', '126']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 122) {
    var is_group = ['128']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 123) {
    var is_group = ['128']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 124) {
    var is_group = ['135', '136']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 125) {
    var is_group = ['137']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 126) {
    var is_group = ['138']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 127) {
    var is_group = ['139']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 128) {
    var is_group = ['140']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 129) {
    var is_group = ['141','142']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 130) {
    var is_group = ['144']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 131) {
    var is_group = ['143']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 132) {
    var is_group = ['145']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 133) {
    var is_group = ['146']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 134) {
    var is_group = ['147', '148']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 135) {
    var is_group = ['149']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 136) {
    var is_group = ['150','151']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 137) {
    var is_group = ['152']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 138) {
    var is_group = ['154','155']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 139) {
    var is_group = ['153']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 140) {
    var is_group = ['156']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 141) {
    var is_group = ['157']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 142) {
    var is_group = ['159']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 143) {
    var is_group = ['160']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 144) {
    var is_group = ['161']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 145) {
    var is_group = ['162']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 146) {
    var is_group = ['163']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 147) {
    var is_group = ['164']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 148) {
    var is_group = ['165']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 149) {
    var is_group = ['166']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 150) {
    var is_group = ['167', '168']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 151) {
    var is_group = ['169', '170']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 152) {
    var is_group = ['171']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 153) {
    var is_group = ['172']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 154) {
    var is_group = ['173']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 155) {
    var is_group = ['174']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 156) {
    var is_group = ['175', '176']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 157) {
    var is_group = ['177']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 158) {
    var is_group = ['178']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 159) {
    var is_group = ['179']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 160) {
    var is_group = ['185', '186']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 161) {
    var is_group = ['181']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 162) {
    var is_group = ['187']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 163) {
    var is_group = ['188']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 164) {
    var is_group = ['189','190']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 165) {
    var is_group = ['191']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 166) {
    var is_group = ['192','193']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 167) {
    var is_group = ['182']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 168) {
    var is_group = ['183']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 169) {
    var is_group = ['194']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 170) {
    var is_group = ['195']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 171) {
    var is_group = ['196','197']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 172) {
    var is_group = ['198']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 173) {
    var is_group = ['199','200']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 174) {
    var is_group = ['201']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 175) {
    var is_group = ['202']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 176) {
    var is_group = ['203']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 177) {
    var is_group = ['205']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 178) {
    var is_group = ['206']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 179) {
    var is_group = ['207']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 180) {
    var is_group = ['208']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 181) {
    var is_group = ['209']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 182) {
    var is_group = ['210', '211']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 183) {
    var is_group = ['212']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 184) {
    var is_group = ['213']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 185) {
    var is_group = ['214']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 207) {
    var is_group = ['240']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 210) {
    var is_group = ['244']
}
else if (tour_id == 888) {
    var is_group = ['59']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}



else if (tour_id == 907) {
    var is_group = ['58','57']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}


else if (tour_id == 908) {
    var is_group = ['61','62']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}

else if (tour_id == 909) {
    var is_group = ['71']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}

else if (tour_id == 910) {
    var is_group = ['69','70']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}



else if (tour_id == 911) {
    var is_group = ['74','73']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}

else if (tour_id == 912) {
    var is_group = ['75','76']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}
else if (tour_id == 913) {
    var is_group = ['77','88']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}


else if (tour_id == 1706) {
    var is_group = ['1706']
    $('.gigafotos-faceApp').addClass('gigafotos');
    $('.gigafotos-faceApp').attr("href", "#");
    $('.gigafotos-faceApp span').text("+ Fotos");
}




else if (tour_id == 77) {
    var is_group = ['1110']
}


// Add Hotspot

window.pin_url = "https://images.gigafans.com/assets/player/pin.svg"
window.pin_style = "gigafantag"


if (tour_id == '8') {
    pin_url = "https://images.gigafans.com/assets/player/Geotag.svg"
    pin_style = "gigafantag"
}

if (tour_id == '9') {
    pin_url = "https://images.gigafans.com/assets/player/pin_sport.svg"
    pin_style = "gigafancbftag"
}

if (tour_id == '10') {
    pin_url = "https://images.gigafans.com/assets/player/pin_sport.svg"
    pin_style = "gigafancbftag"
}
if (tour_id == '14') {
    pin_url = "https://images.gigafans.com/assets/player/pin_sport.svg"
    pin_style = "gigafancbftag"
}

if (tour_id == '15') {
    pin_url = "https://images.gigafans.com/assets/player/pin_sport.svg"
    pin_style = "gigafancbftag"
}


if (tour_id == '20') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}


if (tour_id == '21') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}
if (tour_id == '22') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}
if (tour_id == '707') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}


if (tour_id == '70') {
    pin_url = "https://images.gigafans.com/assets/player/pin_sport.svg"
    pin_style = "gigafantag"
}
if (tour_id == '71') {
    pin_url = "https://images.gigafans.com/assets/player/pin_sport.svg"
    pin_style = "gigafantag"
}
if (tour_id == '76') {
    pin_url = "https://images.gigafans.com/assets/player/pin_gsk.svg"
    pin_style = "gigafantag"
}
if (tour_id == '112') {
    pin_url = "https://images.gigafans.com/assets/player/pin_gsk.svg"
    pin_style = "gigafantag"
}
if (tour_id == '77') {
    pin_url = "https://images.gigafans.com/assets/player/pin_sport.svg"
    pin_style = "gigafantag"
}
if (tour_id == '78') {
    pin_url = "https://images.gigafans.com/assets/player/pin_orlandoCity.svg"
    pin_style = "gigafantag"
}

if (tour_id == '79') {
    pin_url = "https://images.gigafans.com/assets/player/pin_orlandoCity.svg"
    pin_style = "gigafantag"
}
if (tour_id == '81') {
    pin_url = "https://images.gigafans.com/assets/player/pin_sport.svg"
    pin_style = "gigafantag"
}
if (tour_id == '83') {
    pin_url = "https://images.gigafans.com/assets/player/pin_orlandoCity.svg"
    pin_style = "gigafantag"
}
if (tour_id == '84') {
    pin_url = "https://images.gigafans.com/assets/player/pin_orlandoCity.svg"
    pin_style = "gigafantag"
}
if (tour_id == '89') {
    pin_url = "https://images.gigafans.com/assets/player/pin_sport.svg"
    pin_style = "gigafantag"
}

if (tour_id == '92') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}
if (tour_id == '93') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}
if (tour_id == '94') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '95') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '96') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}
if (tour_id == '97') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '98') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '99') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '100') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '101') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '102') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '103') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '104') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '105') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '109') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '111') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '113') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '114') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '115') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '116') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '117') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '120') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '124') {
    pin_url = "https://images.gigafans.com/assets/player/pin_sport.svg"
    pin_style = "gigafantag"
}
if (tour_id == '125') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '129') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}
if (tour_id == '130') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '131') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '133') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '134') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}
if (tour_id == '136') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '138') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}
if (tour_id == '140') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '144') {
    pin_url = "https://images.gigafans.com/assets/player/pin_sport.svg"
    pin_style = "gigafantag"
}

if (tour_id == '145') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '146') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}
if (tour_id == '147') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '148') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '149') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}

if (tour_id == '150') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}

if (tour_id == '151') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}
if (tour_id == '152') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}

if (tour_id == '154') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '156') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}
if (tour_id == '159') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '160') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}
if (tour_id == '164') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}
if (tour_id == '166') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}

if (tour_id == '161') {
    pin_url = "https://images.gigafans.com/assets/player/pin_full.svg"
    pin_style = "gigafantag"
}

if (tour_id == '167') {
    pin_url = "https://images.gigafans.com/assets/player/pin_full.svg"
    pin_style = "gigafantag"
}

if (tour_id == '168') {
    pin_url = "https://images.gigafans.com/assets/player/pin_full.svg"
    pin_style = "gigafantag"
}
if (tour_id == '169') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '173') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '171') {
    pin_url = "https://images.gigafans.com/assets/player/pin_corinthians.svg"
    pin_style = "gigafantag"
}
if (tour_id == '174') {
    pin_url = "https://images.gigafans.com/assets/player/pin_full.svg"
    pin_style = "gigafantag"
}
if (tour_id == '175') {
    pin_url = "https://images.gigafans.com/assets/player/pin_full.svg"
    pin_style = "gigafantag"
}
if (tour_id == '178') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '179') {
    pin_url = "https://images.gigafans.com/assets/player/pin_green.svg"
    pin_style = "gigafantag"
}
if (tour_id == '181') {
    pin_url = "https://images.gigafans.com/assets/player/pin_buser.svg"
    pin_style = "gigafantag"
}
if (tour_id == '184') {
    pin_url = "https://images.gigafans.com/assets/player/pin_buser.svg"
    pin_style = "gigafantag"
}
if (tour_id == '210') {
    pin_url = "https://images.gigafans.com/assets/player/pin_sport.svg"
    pin_style = "gigafantag"
}
if (tour_id == '211') {
    pin_url = "https://images.gigafans.com/assets/player/pin_sport.svg"
    pin_style = "gigafantag"
}


if (tour_id == '290') {
    pin_url = "https://images.gigafans.com/assets/player/pin_orlandoCity.svg"
    pin_style = "gigafantag"
}
if (tour_id == '830') {
    pin_url = "https://images.gigafans.com/assets/player/pin_orlandoCity.svg"
    pin_style = "gigafantag"
}
if (tour_id == '837') {
    pin_url = "https://images.gigafans.com/assets/player/pin_orlandoCity.svg"
    pin_style = "gigafantag"
}
if (tour_id == '907') {
    pin_url = "https://images.gigafans.com/assets/player/pin_orlandoCity.svg"
    pin_style = "gigafantag"
}
if (tour_id == '908') {
    pin_url = "https://images.gigafans.com/assets/player/pin_orlandoCity.svg"
    pin_style = "gigafantag"
}
if (tour_id == '909') {
    pin_url = "https://images.gigafans.com/assets/player/pin_orlandoCity.svg"
    pin_style = "gigafantag"
}
if (tour_id == '910') {
    pin_url = "https://images.gigafans.com/assets/player/pin_orlandoCity.svg"
    pin_style = "gigafantag"
}
if (tour_id == '910') {
    pin_url = "https://images.gigafans.com/assets/player/pin_orlandoCity.svg"
    pin_style = "gigafantag"
}
if (tour_id == '911') {
    pin_url = "https://images.gigafans.com/assets/player/pin_orlandoCity.svg"
    pin_style = "gigafantag"
}
if (tour_id == '912') {
    pin_url = "https://images.gigafans.com/assets/player/pin_orlandoCity.svg"
    pin_style = "gigafantag"
}
if (tour_id == '913') {
    pin_url = "https://images.gigafans.com/assets/player/pin_orlandoCity.svg"
    pin_style = "gigafantag"
}


/*-------------------------------*/
/*          select Quiz          */
/*-------------------------------*/

$('#quiz input').click(function (event) {

    var selected = $(this).attr('name');
    var quiz_id = '8';
    if (tour_id == '9') {
        quiz_id = '7'
    }

    if (tour_id == '10') {
        quiz_id = '7'
    }


    if (tour_id == '11') {
        quiz_id = '9'
    }

    if (tour_id == '12') {
        quiz_id = '9'
    }

    if (tour_id == '14') {
        quiz_id = '7'
    }

    if (tour_id == '15') {
        quiz_id = '7'
    }

    if (tour_id == '16') {
        quiz_id = '9'
    }

    if (tour_id == '17') {
        quiz_id = '10'
    }

    if (tour_id == '18') {
        quiz_id = '10'
    }
    if (tour_id == '19') {
        quiz_id = '11'
    }

    if (tour_id == '20') {
        quiz_id = '12'
    }

    if (tour_id == '21') {
        quiz_id = '26'
    }

    if (tour_id == '22') {
        quiz_id = '12'
    }

    if (tour_id == '23') {
        quiz_id = '13'
    }

    if (tour_id == '25') {
        quiz_id = '13'
    }


    if (tour_id == '70') {
        quiz_id = '14'
    }
    if (tour_id == '71') {
        quiz_id = '15'
    }
    if (tour_id == '72') {
        quiz_id = '16'
    }
    if (tour_id == '73') {
        quiz_id = '17'
    }
    if (tour_id == '74') {
        quiz_id = '18'
    }

    if (tour_id == '75') {
        quiz_id = '19'
    }
    if (tour_id == '76') {
        quiz_id = '20'
    }

    if (tour_id == '78') {
        quiz_id = '21'
    }


    if (tour_id == '79') {
        quiz_id = '21'
    }

    if (tour_id == '80') {
        quiz_id = '22'
    }
    if (tour_id == '81') {
        quiz_id = '00'
    }
    if (tour_id == '82') {
        quiz_id = '23'
    }
    if (tour_id == '83') {
        quiz_id = '24'
    }

    if (tour_id == '84') {
        quiz_id = '25'
    }

    if (tour_id == '300') {
        quiz_id = '27'
    }
    if (tour_id == '55') {
        quiz_id = '28'
    }


    if (tour_id == '87') {
        quiz_id = '29'
    }


    if (tour_id == '86') {
        quiz_id = '30'
    }
    if (tour_id == '94') {
        quiz_id = '32'
    }
    if (tour_id == '95') {
        quiz_id = '33'
    }
    if (tour_id == '97') {
        quiz_id = '34'
    }
    if (tour_id == '99') {
        quiz_id = '35'
    }



    // dev
    if (tour_id == '77') {
        quiz_id = '00'
    }



    $.ajax({
        url: '/api/v1/quiz.json?tour_id=' + tour_id + '&user_id=' + currentUser.user_id + '&quiz_id=' + quiz_id + '&option=' + selected,
        type: 'POST',
        success: function (data) {

            get_q = data.status

            $.each(get_q, function (i, value) {
                calc = Math.floor((value * 100) / data.total);
                $('.answer .' + i).css('width', calc + '%');
                $('.answer .' + i).text(calc + ' %');
            });

        },
        beforeSend: function () { $('.overlay').show(); $('.loading').addClass('loading-animate'); },
        complete: function () {
            $('.overlay').hide();
            $('.loading').removeClass('loading-animate');
            $('#quiz .question').hide();
            $('#quiz .answer').show();
        }
    });

    setTimeout(function () {

    }, 1000);


});


/*-------------------------------*/
/*       Share this moment       */
/*-------------------------------*/

$('.send-share').click(function (event) {

    var IDhotspot = $('.card').attr('id');
    var filter_url = 'https://player.gigafans.com/?gigacard=';
    if (tour_id == '7') {
        filter_url = 'https://villamix.gigafans.com/20171007?gigacard='
    }

    if (tour_id == '8') {
        filter_url = 'https://carnavalsedaboom.gigafans.com?gigacard='
    }

    // if (tour_id == '8') {
    //     filter_url = 'https://villamix.gigafans.com/201710072?gigacard='
    // }


    if (tour_id == '10') {
        filter_url = 'https://cbf.gigafans.com/201710102?gigacard='
    }

    if (tour_id == '9') {
        filter_url = 'https://cbf.gigafans.com/20171010?gigacard='
    }

    if (tour_id == '11') {
        filter_url = 'https://fernandoesorocaba.gigafans.com/20171011?gigacard='
    }

    if (tour_id == '16') {
        filter_url = 'https://fernandoesorocaba.gigafans.com/201710112?gigacard='
    }

    if (tour_id == '17') {
        filter_url = 'https://nbb.gigafans.com/20171104?gigacard='
    }

    if (tour_id == '18') {
        filter_url = 'https://nbb.gigafans.com/201711042?gigacard='
    }

    if (tour_id == '19') {
        filter_url = 'https://cis.gigafans.com/20171104?gigacard='
    }

    if (tour_id == '55') {
        filter_url = 'https://cis.gigafans.com/2018/02?gigacard='
    }
    if (tour_id == '20') {
        filter_url = 'https://corinthians.gigafans.com/brasileirao2017/cor-x-atl-foto1?gigacard='
    }
    if (tour_id == '21') {
        filter_url = 'https://corinthians.gigafans.com/2018/treino?gigacard='
    }
    if (tour_id == '22') {
        filter_url = 'https://corinthians.gigafans.com/brasileirao2017/cor-x-atl-mosaico?gigacard='
    }
    if (tour_id == '23') {
        filter_url = 'https://saopaulo.gigafans.com/brasileirao2017/sao-x-bah-foto1?gigacard='
    }

    if (tour_id == '25') {
        filter_url = 'https://saopaulo.gigafans.com/brasileirao2017/sao-x-bah-foto2?gigacard='
    }

    if (tour_id == '70') {
        filter_url = 'https://vemprorio.gigafans.com/?gigacard='
    }
    if (tour_id == '71') {
        filter_url = 'https://encontrodosamba.gigafans.com/?gigacard='
    }
    if (tour_id == '72') {
        filter_url = 'https://floridacup.gigafans.com/2018/cor_x_psv/?gigacard='
    }
    if (tour_id == '73') {
        filter_url = 'https://floridacup.gigafans.com/2018/atl_x_ran/?gigacard='
    }
    if (tour_id == '74') {
        filter_url = 'https://fanfest.gigafans.com/2018/fernando&sorocaba/?gigacard='
    }
    if (tour_id == '75') {
        filter_url = 'https://fanfest.gigafans.com/2018/boyceavenue/?gigacard='
    }
    if (tour_id == '76') {
        filter_url = 'https://gsk.gigafans.com/?gigacard='
    }
    if (tour_id == '112') {
        filter_url = 'https://gsk.gigafans.com/2019/?gigacard='
    }
    if (tour_id == '77') {
        filter_url = 'https://dev.gigafans.com/?gigacard='
    }
    if (tour_id == '78') {
        filter_url = 'https://orlandocity.gigafans.com/?gigacard='
    }
    if (tour_id == '79') {
        filter_url = 'https://orlandocity.gigafans.com/2018/game1/?gigacard='
    }
    if (tour_id == '80') {
        filter_url = 'https://jogodasestrelas.gigafans.com/?gigacard='
    }
    if (tour_id == '81') {
        filter_url = 'https://mcdonalds.gigafans.com/?gigacard='
    }
    if (tour_id == '82') {
        filter_url = 'https://spc.gigafans.com/?gigacard='
    }
    if (tour_id == '83') {
        filter_url = 'https://orlandocity.gigafans.com/2018/game3/?gigacard='
    }
    if (tour_id == '84') {
        filter_url = 'https://spc.gigafans.com/2018/game2/?gigacard='
    }
    if (tour_id == '86') {
        filter_url = 'https://eola.gigafans.com/?gigacard='
    }
    if (tour_id == '87') {
        filter_url = 'https://redelopes.gigafans.com/?gigacard='
    }
    if (tour_id == '88') {
        filter_url = 'https://lopes.gigafans.com/?gigacard='
    }
    if (tour_id == '89') {
        filter_url = 'https://riseup.gigafans.com/?gigacard='
    }
    if (tour_id == '90') {
        filter_url = 'https://cis.gigafans.com/2018/03?gigacard='
    }
    if (tour_id == '92') {
        filter_url = 'http://corinthians.gigafans.com/2018/libertadores/cor-x-col?gigacard='
    }
    if (tour_id == '93') {
        filter_url = 'http://corinthians.gigafans.com/2018/brasileiro/cor-x-atl?gigacard='
    }
    if (tour_id == '94') {
        filter_url = 'https://fotoverdao360.gigafans.com/2018/brasileirao/Pal-x-Atl?gigacard='
    }
    if (tour_id == '95') {
        filter_url = 'https://fotoverdao360.gigafans.com/2018/brasileirao/Pal-x-Cor?gigacard='
    }
    if (tour_id == '96') {
        filter_url = 'https://corinthians.gigafans.com/2018/copadobrasil/Cor-x-Fla?gigacard='
    }
    if (tour_id == '97') {
        filter_url = 'https://fotoverdao360.gigafans.com/2018/brasileirao/Pal-x-Cru?gigacard='
    }
    if (tour_id == '98') {
        filter_url = 'https://fotoverdao360.gigafans.com/2018/libertadores/Pal-x-Col?gigacard='
    }
    if (tour_id == '99') {
        filter_url = 'https://fotoverdao360.gigafans.com/2018/brasileirao/Pal-x-Gre?gigacard='
    }
    if (tour_id == '100') {
        filter_url = 'https://fotoverdao360.gigafans.com/2018/brasileirao/Pal-x-Cea?gigacard='
    }
    if (tour_id == '101') {
        filter_url = 'https://fotoverdao360.gigafans.com/2018/brasileirao/Pal-x-San?gigacard='
    }
    if (tour_id == '102') {
        filter_url = 'https://fotoverdao360.gigafans.com/2018/brasileirao/Pal-x-Flu?gigacard='
    }
    if (tour_id == '103') {
        filter_url = 'https://fotoverdao360.gigafans.com/2018/brasileirao/Pal-x-Ame?gigacard='
    }
    if (tour_id == '104') {
        filter_url = 'https://fotoverdao360.gigafans.com/2018/brasileirao/Pal-x-Vit?gigacard='
    }
    if (tour_id == '105') {
        filter_url = 'https://fotoverdao360.gigafans.com/2018/brasileirao/Pal-x-Vit?gigacard='
    }
    if (tour_id == '108') {
        filter_url = 'https://flamengo.gigafans.com/2019/carioca/Fla-x-Ban?gigacard='
    }
    if (tour_id == '109') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/paulista/Pal-x-Bot?gigacard='
    }
    if (tour_id == '110') {
        filter_url = 'https://flamengo.gigafans.com/2019/carioca/Fla-x-Boa?gigacard='
    }
    
    if (tour_id == '111') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/paulista/Pal-x-Bra?gigacard='
    }
    if (tour_id == '113') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/paulista/Pal-x-San?gigacard='
    }
    if (tour_id == '114') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/paulista/Pal-x-Itu?gigacard='
    }
    if (tour_id == '115') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/libertadores/Pal-x-Mel?gigacard='
    }
    if (tour_id == '116') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/paulista/Pal-x-Pon?gigacard='
    }
    if (tour_id == '117') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/paulista/Pal-x-Nov?gigacard='
    }
    if (tour_id == '118') {
        filter_url = 'https://flamengo.gigafans.com/2019/libertadores/Fla-x-Pen?gigacard='
    }
    if (tour_id == '119') {
        filter_url = 'https://flamengo.gigafans.com/2019/carioca/Fla-x-Flu?gigacard='
    }
    if (tour_id == '120') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/libertadores/Pal-x-Jun?gigacard='
    }
    if (tour_id == '121') {
        filter_url = 'https://flamengo.gigafans.com/2019/libertadores/Fla-x-San?gigacard='
    }
    if (tour_id == '122') {
        filter_url = 'https://saopaulo.gigafans.com/2019/paulista/Sao-x-Cor?gigacard='
    }
    if (tour_id == '123') {
        filter_url = 'https://saopaulo.gigafans.com/2019/paulista/Sao-x-Cor-?gigacard='
    }
    if (tour_id == '124') {
        filter_url = 'https://corinthians.gigafans.com/2019/paulista/Cor-x-Sao?gigacard='
    }
    if (tour_id == '125') {
        filter_url = 'https://flamengo.gigafans.com/2019/carioca/Fla-x-Vas?gigacard='
    }
    if (tour_id == '126') {
        filter_url = 'https://hinodefest.gigafans.com/2019/?gigacard='
    }
    if (tour_id == '127') {
        filter_url = 'https://flamengo.gigafans.com/2019/brasileirao/fla-x-cru?gigacard='
    }
    if (tour_id == '128') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/brasileirao/pal-x-for?gigacard='
    }
    if (tour_id == '129') {
        filter_url = 'https://corinthians.gigafans.com/2019/brasileirao/cor-x-cha?gigacard='
    }
    if (tour_id == '130') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/brasileirao/pal-x-int?gigacard='
    }
    if (tour_id == '131') {
        filter_url = 'https://fluminense.gigafans.com/2019/brasileirao/flu-x-goi?gigacard='
    }
    if (tour_id == '132') {
        filter_url = 'https://saopaulo.gigafans.com/2019/brasileirao/sao-x-fla?gigacard='
    }
    if (tour_id == '133') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/libertadores/pal-x-slo?gigacard='
    }
    if (tour_id == '134') {
        filter_url = 'https://corinthians.gigafans.com/2019/brasileirao/cor-x-gre?gigacard='
    }
    if (tour_id == '135') {
        filter_url = 'https://flamengo.gigafans.com/2019/brasileirao/fla-x-cha?gigacard='
    }
    if (tour_id == '136') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/brasileirao/pal-x-san?gigacard='
    }
    if (tour_id == '137') {
        filter_url = 'https://saopaulo.gigafans.com/2019/brasileirao/sao-x-bah?gigacard='
    }
    if (tour_id == '138') {
        filter_url = 'https://corinthians.gigafans.com/2019/brasileirao/cor-x-sao?gigacard='
    }
    if (tour_id == '139') {
        filter_url = 'https://flamengo.gigafans.com/2019/brasileirao/fla-x-ath?gigacard='
    }
    if (tour_id == '140') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/copadobrasil/pal-x-sam?gigacard='
    }
    if (tour_id == '141') {
        filter_url = 'https://flamengo.gigafans.com/2019/brasileirao/fla-x-for?gigacard='
    }
    if (tour_id == '142') {
        filter_url = 'https://saopaulo.gigafans.com/2019/basquete/sao-x-uni?gigacard='
    }
    if (tour_id == '143') {
        filter_url = 'https://flamengo.gigafans.com/2019/copadobrasil/fla-x-cor?gigacard='
    }
    if (tour_id == '144') {
        filter_url = 'https://riseup.gigafans.com/2019/?gigacard='
    }
    if (tour_id == '145') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/brasileirao/pal-x-cap?gigacard='
    }
    if (tour_id == '146') {
        filter_url = 'https://botafogo.gigafans.com/2019/brasileirao/bot-x-gre?gigacard='
    }
    if (tour_id == '147') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/brasileirao/pal-x-ava?gigacard='
    }
    if (tour_id == '148') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/copadobrasil/pal-x-int?gigacard='
    }
    if (tour_id == '149') {
        filter_url = 'https://fluminense.gigafans.com/2019/brasileirao/flu-x-cea?gigacard='
    }
    if (tour_id == '150') {
        filter_url = 'https://corinthians.gigafans.com/2019/brasileirao/cor-x-fla?gigacard='
    }
    if (tour_id == '151') {
        filter_url = 'https://corinthians.gigafans.com/2019/sulamericana/cor-x-mon?gigacard='
    }
    if (tour_id == '152') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/brasileirao/pal-x-vas?gigacard='
    }
    if (tour_id == '153') {
        filter_url = 'https://flamengo.gigafans.com/2019/brasileirao/fla-x-bot?gigacard='
    }
    if (tour_id == '154') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/libertadores/pal-x-god?gigacard='
    }
    if (tour_id == '155') {
        filter_url = 'https://flamengo.gigafans.com/2019/libertadores/fla-x-eme?gigacard='
    }
    if (tour_id == '156') {
        filter_url = 'https://corinthians.gigafans.com/2019/brasileirao/cor-x-pal?gigacard='
    }
    if (tour_id == '157') {
        filter_url = 'https://saopaulo.gigafans.com/2019/brasileirao/sao-x-san?gigacard='
    }
    if (tour_id == '158') {
        filter_url = 'https://flamengo.gigafans.com/2019/brasileirao/fla-x-gre?gigacard='
    }
    if (tour_id == '159') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/brasileirao/pal-x-bah?gigacard='
    }
    if (tour_id == '160') {
        filter_url = 'https://corinthians.gigafans.com/2019/brasileirao/cor-x-bot?gigacard='
    }
    if (tour_id == '161') {
        filter_url = 'https://daniel.gigafans.com/sp?gigacard='
    }
    if (tour_id == '162') {
        filter_url = 'https://saopaulo.gigafans.com/2019/brasileirao/sao-x-cea?gigacard='
    }
    if (tour_id == '163') {
        filter_url = 'https://flamengo.gigafans.com/2019/libertadores/fla-x-int?gigacard='
    }
    if (tour_id == '164') {
        filter_url = 'https://corinthians.gigafans.com/2019/sulamericana/cor-x-flu?gigacard='
    }
    if (tour_id == '165') {
        filter_url = 'https://flamengo.gigafans.com/2019/brasileirao/fla-x-pal?gigacard='
    }
    if (tour_id == '166') {
        filter_url = 'https://corinthians.gigafans.com/2019/brasileirao/cor-x-atl?gigacard='
    }
    if (tour_id == '167') {
        filter_url = 'https://daniel.gigafans.com/objetivo?gigacard='
    }
    if (tour_id == '168') {
        filter_url = 'https://daniel.gigafans.com/vivorio?gigacard='
    }
    if (tour_id == '169') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/brasileirao/pal-x-flu?gigacard='
    }
    if (tour_id == '170') {
        filter_url = 'https://flamengo.gigafans.com/2019/brasileirao/fla-x-san?gigacard='
    }
    if (tour_id == '171') {
        filter_url = 'https://corinthians.gigafans.com/2019/brasileirao/cor-x-bah?gigacard='
    }
    if (tour_id == '172') {
        filter_url = 'https://flamengo.gigafans.com/2019/brasileirao/fla-x-int?gigacard='
    }
    if (tour_id == '173') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/brasileirao/pal-x-csa?gigacard='
    }
    if (tour_id == '174') {
        filter_url = 'https://fullsail.gigafans.com/bts/2019/october?gigacard='
    }
    if (tour_id == '175') {
        filter_url = 'https://fullsail.gigafans.com/alumni/2019/october?gigacard='
    }
    if (tour_id == '176') {
        filter_url = 'https://flamengo.gigafans.com/2019/libertadores/fla-x-gre?gigacard='
    }
    if (tour_id == '177') {
        filter_url = 'https://flamengo.gigafans.com/2019/brasileirao/fla-x-csa?gigacard='
    }
    if (tour_id == '178') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/brasileirao/pal-x-sao?gigacard='
    }
    if (tour_id == '179') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/brasileirao/pal-x-cea?gigacard='
    }
    if (tour_id == '180') {
        filter_url = 'https://flamengo.gigafans.com/2019/brasileirao/fla-x-cor?gigacard='
    }
    if (tour_id == '181') {
        filter_url = 'https://botafogo.gigafans.com/2019/brasileirao/bot-x-fla?gigacard='
    }
    if (tour_id == '182') {
        filter_url = 'https://fotoverdao360.gigafans.com/2019/brasileirao/pal-x-cor?gigacard='
    }
    if (tour_id == '183') {
        filter_url = 'https://flamengo.gigafans.com/2019/brasileirao/fla-x-bah?gigacard='
    }
    if (tour_id == '184') {
        filter_url = 'https://botafogo.gigafans.com/2019/brasileirao/bot-x-ava?gigacard='
    }
    if (tour_id == '185') {
        filter_url = 'https://flamengo.gigafans.com/2019/brasileirao/fla-x-vas?gigacard='
    }

    if (tour_id == '199') {
        filter_url = 'https://saopaulo.gigafans.com/2020/covid?gigacard='
    }

    if (tour_id == '200') {
        filter_url = 'https://corinthians.gigafans.com/2020/covid?gigacard='
    }

    if (tour_id == '201') {
        filter_url = 'https://santos.gigafans.com/2020/covid?gigacard='
    }
    
    if (tour_id == '202') {
        filter_url = 'https://fotoverdao360.gigafans.com/2020/covid?gigacard='
    }

    if (tour_id == '203') {
        filter_url = 'https://flamengo.gigafans.com/2020/covid?gigacard='
    }

    if (tour_id == '204') {
        filter_url = 'https://fluminense.gigafans.com/2020/covid?gigacard='
    }

    if (tour_id == '205') {
        filter_url = 'https://botafogo.gigafans.com/2020/covid?gigacard='
    }

    if (tour_id == '206') {
        filter_url = 'https://vasco.gigafans.com/2020/covid?gigacard='
    }
    
    if (tour_id == '207') {
        filter_url = 'https://corinthians.gigafans.com/2020/paulista/cor-x-pal?gigacard='
    }

    if (tour_id == '210') {
        filter_url = 'https://bahia.gigafans.com/2023/baiano/bah-x-jac?gigacard='
    }
    

    

    if (tour_id == '707') {
        filter_url = 'https://fpf.gigafans.com/2018/final/?gigacard='
    }

    // var url = filter_url + IDhotspot + tour_id;
    var url = filter_url + IDhotspot;

    if (window.currentUser.provider == 'facebook.com') {
        $('a.share-facebook').attr('href', 'https://www.facebook.com/dialog/share?app_id=120323028676102&display=popup&href=' + url);
        $('a.share-facebook').get(0).click();
    } else if (window.currentUser.provider == 'twitter.com') {
        $('a.share-twitter').attr('href', 'http://twitter.com/share?text=%40Gigafans%20&url=' + url);
        $('a.share-twitter').get(0).click();
    } else {
        $('a.share-google').attr('href', 'https://plus.google.com/share?url=' + url);
        $('a.share-google').get(0).click();
    }

    return false;
});

$("a.share-twitter").on("click", function (e) {
    e.preventDefault();

    windowPopup($(this).attr("href"), 500, 300);
});

$("a.share-facebook").on("click", function (e) {
    e.preventDefault();

    windowPopup($(this).attr("href"), 500, 300);
});

$("a.share-google").on("click", function (e) {
    e.preventDefault();

    windowPopup($(this).attr("href"), 500, 300);
});

function windowPopup(url, width, height) {
    // Calculate the position of the popup so
    // it’s centered on the screen.
    var left = (screen.width / 2) - (width / 2),
        top = (screen.height / 2) - (height / 2);

    window.open(
        url,
        "",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left
    );
}
